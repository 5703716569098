import React, { useEffect } from 'react';

const Ad = (props) => {
    const { dataAdSlot } = props;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.log(e);
        }
    }, []);



    return (
        <div style={{ textAlign: "center" }}>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-4630760751452941"
                data-ad-slot={dataAdSlot}
                data-full-width-responsive="true">
            </ins>
        </div>
    );
};

export default Ad;
