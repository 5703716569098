import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/spikes_unwritten_guide.png"

class HomeButton extends React.Component {
    render() {
        return (
            <Link to="/">
                <img
                    src={logo}
                    alt="Spike's Rewritten Guide"
                />
                <i className="fa fa-home"></i>
            </Link>
        );
    }
}

export default HomeButton;
