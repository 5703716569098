import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

// Set the app element
Modal.setAppElement('#root');

class WhatsNewPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        const currentVersion = '1.0.0'; // Update this version number with each new release
        const hasSeenPopup = localStorage.getItem(`hasSeenWhatsNew_${currentVersion}`);
        if (!hasSeenPopup) {
            this.setState({ isOpen: true });
            localStorage.setItem(`hasSeenWhatsNew_${currentVersion}`, 'true');
        }
    }

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    render() {
        return (
            <Modal
                isOpen={this.state.isOpen}
                onRequestClose={this.closeModal}
                className="whatsnew-popup"
            >
                <h2>What's New on Spike's Rewritten Guide</h2>
                <hr />
                <div>
                    Added new page detailing the <Link to="/undernewmanagement">Under New Management</Link> update.
                </div>
                <hr />
                <div>
                    Updated cog facility information to reflect the Under New Management update.
                </div>
                <hr />
                <div>
                    Added information about <Link to="/statuseffects">status effects</Link> seen in cog battles.
                </div>
                <hr />
                <div>
                    See more updates on the <Link to="/whatsnew">What's New</Link> page!
                </div>
                <br />
                <br />
                <button onClick={this.closeModal}>Close</button>
            </Modal>
        );
    }
}

export default WhatsNewPopup;
