import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const isVisible = window.scrollY > window.innerHeight;
        setIsVisible(isVisible);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`backtotop-link ${isVisible ? 'show' : ''}`} id="backtotop-link">
            <a href="#return-top">
                <button>Back to top</button>
            </a>
        </div>
    );
}
