import React from "react";
import { Link } from "react-router-dom";
import Statuseffect_accuracydown from "../assets/images/Statuseffect_accuracydown.png";
import Statuseffect_accuracyup_Cog from "../assets/images/Statuseffect_accuracyup_Cog.png";
import Statuseffect_accuracyup_Toon from "../assets/images/Statuseffect_accuracyup_Toon.png";
import Statuseffect_attentive from "../assets/images/Statuseffect_attentive.png";
import Statuseffect_bearmarket from "../assets/images/Statuseffect_bearmarket.png";
import Statuseffect_bullmarket from "../assets/images/Statuseffect_bullmarket.png";
import Statuseffect_defensedown from "../assets/images/Statuseffect_defensedown.png";
import Statuseffect_defenseup_Cog from "../assets/images/Statuseffect_defenseup_Cog.png";
import Statuseffect_defenseup_Toon from "../assets/images/Statuseffect_defenseup_Toon.png";
import Statuseffect_defensivestrategy from "../assets/images/Statuseffect_defensivestrategy.png";
import Statuseffect_delaydmg from "../assets/images/Statuseffect_delaydmg.png";
import Statuseffect_dmgovertime from "../assets/images/Statuseffect_dmgovertime.png";
import Statuseffect_dmgup_Cog from "../assets/images/Statuseffect_dmgup_Cog.png";
import Statuseffect_dmgup_Toon from "../assets/images/Statuseffect_dmgup_Toon.png";
import Statuseffect_expendable from "../assets/images/Statuseffect_expendable.png";
import Statuseffect_extraturn from "../assets/images/Statuseffect_extraturn.png";
import Statuseffect_fieldpromo from "../assets/images/Statuseffect_fieldpromo.png";
import Statuseffect_firedup from "../assets/images/Statuseffect_firedup.png";
import Statuseffect_gaglocked from "../assets/images/Statuseffect_gaglocked.png";
import Statuseffect_gagtax from "../assets/images/Statuseffect_gagtax.png";
import Statuseffect_healovertime from "../assets/images/Statuseffect_healovertime.png";
import Statuseffect_immunity from "../assets/images/Statuseffect_immunity.png";
import Statuseffect_lured from "../assets/images/Statuseffect_lured.png";
import Statuseffect_meltdown from "../assets/images/Statuseffect_meltdown.png";
import Statuseffect_objection from "../assets/images/Statuseffect_objection.png";
import Statuseffect_offlimits from "../assets/images/Statuseffect_offlimits.png";
import Statuseffect_overpaid from "../assets/images/Statuseffect_overpaid.png";
import Statuseffect_reinforced from "../assets/images/Statuseffect_reinforced.png";
import Statuseffect_remotedmg from "../assets/images/Statuseffect_remotedmg.png";
import Statuseffect_remoteheal from "../assets/images/Statuseffect_remoteheal.png";
import Statuseffect_silence from "../assets/images/Statuseffect_silence.png";
import Statuseffect_tenure from "../assets/images/Statuseffect_tenure.png";
import Statuseffect_timedown from "../assets/images/Statuseffect_timedown.png";
import Statuseffect_trapped from "../assets/images/Statuseffect_trapped.png";
import Statuseffect_unitecd from "../assets/images/Statuseffect_unitecd.png";
import PanelNavigation from "../components/PanelNavigation";

class StatusEffects extends React.Component {
    render() {
        const panelNavigationLinks = [
            {
                href: "#general",
                text: "General",
            },
            {
                href: "#facility",
                text: "Facility Only",
            },
            {
                href: "#boiler",
                text: "The Boiler Only",
            },
        ];

        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Status Effects</h1>
                    <p>
                        Status effects are unique battle icons that signal helpful,
                        harmful, or neutral situations during Cog battles.
                        These icons pop up above the health interface for Cogs
                        and the gag selection interface for Toons. Hover over a
                        status effect to get a description, often with a number indicating
                        how long the effect lasts. Neat, huh? Status effects for Cogs
                        have a squared background, while Toons get a circular one.
                    </p>
                    <PanelNavigation links={panelNavigationLinks} adSlot="8689384557" />
                    <span className="anchor" id="general"></span>
                    <h2>General</h2>
                    <p>
                        These status effects can occur in most battles in Toontown.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Status Effect
                                </th>
                                <th>
                                    Icon
                                </th>
                                <th style={{ width: 60 + "%" }}>
                                    Description
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Trapped
                                </td>
                                <td>
                                    <img src={Statuseffect_trapped} alt="Trapped" />
                                </td>
                                <td>
                                    Trap gags deal damage against Cogs when sprung after Lure usage.
                                    Lure gags also have an additional 10% accuracy when an affected Cog is trapped.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Lured
                                </td>
                                <td>
                                    <img src={Statuseffect_lured} alt="Lured" />
                                </td>
                                <td>
                                    Cogs cannot move and are awoken when damaged or when Lure expires.
                                    All gags targeting lured Cogs are guaranteed to hit, except for Drop.
                                    Throw and Squirt gags also deal 50% additional damage.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Remote Controlled (damage)
                                </td>
                                <td>
                                    <img src={Statuseffect_remotedmg} alt="Remote Controlled (damage)" />
                                </td>
                                <td>
                                    Controlled Cogs tell a bad joke and deal damage to other Cogs while
                                    also receiving damage upon expiration. The number of rounds, damage,
                                    and damage upon expiration vary depending on the tier of a
                                    damage remote.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Remote Controlled (healing)
                                </td>
                                <td>
                                    <img src={Statuseffect_remoteheal} alt="Remote Controlled (healing)" />
                                </td>
                                <td>
                                    Controlled Cogs perform a dance and heal Toons while also receiving
                                    damage upon expiration. The number of rounds, healing amount,
                                    and damage upon expiration vary depending on the tier of a
                                    healing remote.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Accuracy Down
                                </td>
                                <td>
                                    <img src={Statuseffect_accuracydown} alt="Accuracy Down" />
                                </td>
                                <td>
                                    Cogs Miss SOS Toons apply the effect to reduce the accuracy of cog attacks by 75%.
                                    The number of rounds varies depending on the tier of a Cogs Miss SOS Toon.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Accuracy Up
                                </td>
                                <td>
                                    <img src={Statuseffect_accuracyup_Cog} alt="Accuracy Up" />
                                    <img src={Statuseffect_accuracyup_Toon} alt="Accuracy Up" />
                                </td>
                                <td>
                                    Toons Hit SOS Toons apply the effect to increase the accuracy of Toons' gags by 75%.
                                    The number of rounds varies depending on the tier of a Toons Hit SOS Toon.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Reinforced Plating
                                </td>
                                <td>
                                    <img src={Statuseffect_reinforced} alt="Reinforced Plating" />
                                </td>
                                <td>
                                    Version 2.0 Cogs become Skelecogs when defeated and gain a turn to attack during
                                    the same round in which they become a Skelecog.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Unite Cooldown
                                </td>
                                <td>
                                    <img src={Statuseffect_unitecd} alt="Unite Cooldown" />
                                </td>
                                <td>
                                    Toon-Up and Gag-Up unites are subjected to a cooldown for 3 rounds when used by Toons.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="facility"></span>
                    <h2>Facility Only</h2>
                    <p>
                        These status effects are exclusive to Cog Facilities, such as{" "}
                        <Link to="/fieldoffices">The Boiler in Sellbot Field Offices</Link>{" "}
                        and the <Link to="/undernewmanagement">Supervisors</Link> in factories,
                        mints, offices, and golf courses.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Status Effect
                                </th>
                                <th>
                                    Icon
                                </th>
                                <th>
                                    Description
                                </th>
                                <th style={{ width: 30 + "%" }}>
                                    Source
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Damage Over Time
                                </td>
                                <td>
                                    <img src={Statuseffect_dmgovertime} alt="Damage Over Time" />
                                </td>
                                <td>
                                    Damage is inflicted on Toons at the end of every round.
                                </td>
                                <td>
                                    Slow Burn (The Boiler); Burnout (The Factory Foreman)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gag Ordinance
                                </td>
                                <td>
                                    <img src={Statuseffect_gaglocked} alt="Gag Ordinance" />
                                </td>
                                <td>
                                    Toons are restricted from using gags of a specific track for a
                                    certain number of rounds.
                                </td>
                                <td>
                                    Quality Control (The Boiler);
                                    Objection Sustained (The Office Clerk);
                                    Sandtrapped (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Damage Up
                                </td>
                                <td>
                                    <img src={Statuseffect_dmgup_Cog} alt="Damage Up" />
                                    <img src={Statuseffect_dmgup_Toon} alt="Damage Up" />
                                </td>
                                <td>
                                    Cogs or Toons deal additional damage.
                                </td>
                                <td>
                                    Fired Up (The Boiler);
                                    Overtime (The Factory Foreman);
                                    Bull Market (The Mint Auditor);
                                    Book Smart (The Office Clerk);
                                    Driving Range (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Defense Up
                                </td>
                                <td>
                                    <img src={Statuseffect_defenseup_Cog} alt="Defense Up" />
                                    <img src={Statuseffect_defenseup_Toon} alt="Defense Up" />
                                </td>
                                <td>
                                    Cogs or Toons take less damage.
                                </td>
                                <td>
                                    Market Research (The Boiler); Bear Market (The Mint Auditor)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Accuracy Up
                                </td>
                                <td>
                                    <img src={Statuseffect_accuracyup_Cog} alt="Accuracy Up" />
                                </td>
                                <td>
                                    Cogs have increased accuracy for their attacks.
                                </td>
                                <td>
                                    Market Research (The Boiler)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Repair Over Time
                                </td>
                                <td>
                                    <img src={Statuseffect_healovertime} alt="Repair Over Time" />
                                </td>
                                <td>
                                    Cogs are healed at the end of each round.
                                </td>
                                <td>
                                    Pay Raise (The Boiler)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tenure
                                </td>
                                <td>
                                    <img src={Statuseffect_tenure} alt="Tenure" />
                                </td>
                                <td>
                                    Cogs are immune to pink slips and remote controls.
                                </td>
                                <td>
                                    All Supervisor cogs and all cogs with The Mint Auditor have the effect by default.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Attentive
                                </td>
                                <td>
                                    <img src={Statuseffect_attentive} alt="Attentive" />
                                </td>
                                <td>
                                    The Lure effect on cogs is reduced to a single turn.
                                </td>
                                <td>
                                    All Supervisor cogs have the effect by default;
                                    Investment (The Mint Auditor);
                                    Book Smart and Witness Protection (The Office Clerk);
                                    Overwork (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Overpaid
                                </td>
                                <td>
                                    <img src={Statuseffect_overpaid} alt="Overpaid" />
                                </td>
                                <td>
                                    Cogs have additional health.
                                </td>
                                <td>
                                    All cogs with The Mint Auditor have the effect by default.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Fired Up
                                </td>
                                <td>
                                    <img src={Statuseffect_fieldpromo} alt="Fired Up" />
                                </td>
                                <td>
                                    Cogs have increased damage but their defense decreases.
                                </td>
                                <td>
                                    Field Promotion (The Factory Foreman)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Time Down
                                </td>
                                <td>
                                    <img src={Statuseffect_timedown} alt="Time Down" />
                                </td>
                                <td>
                                    The battle timer is reduced by several seconds.
                                </td>
                                <td>
                                    Overtime (The Factory Foreman)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bear Market
                                </td>
                                <td>
                                    <img src={Statuseffect_bearmarket} alt="Bear Market" />
                                </td>
                                <td>
                                    Gags cost double and Toons have increased defense.
                                </td>
                                <td>
                                    Bear Market (The Mint Auditor)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bull Market
                                </td>
                                <td>
                                    <img src={Statuseffect_bullmarket} alt="Bull Market" />
                                </td>
                                <td>
                                    Cogs and Toons have increased damage.
                                </td>
                                <td>
                                    Bull Market (The Mint Auditor)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gag Tax
                                </td>
                                <td>
                                    <img src={Statuseffect_gagtax} alt="Gag Tax" />
                                </td>
                                <td>
                                    Gags cost double. The effect is removed when Bear Market is inactive.
                                </td>
                                <td>
                                    Bear Market (The Mint Auditor)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Silence
                                </td>
                                <td>
                                    <img src={Statuseffect_silence} alt="Silence" />
                                </td>
                                <td>
                                    The Supervisor Cog retaliates if they are damaged by Sound gags.
                                </td>
                                <td>
                                    The Office Clerk has the effect by default.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Objection
                                </td>
                                <td>
                                    <img src={Statuseffect_objection} alt="Objection" />
                                </td>
                                <td>
                                    Toons must use Sound, Throw, Squirt, or Drop gags in the next round
                                    or receive additional damage.
                                </td>
                                <td>
                                    Objection (The Office Clerk)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gag Immunity
                                </td>
                                <td>
                                    <img src={Statuseffect_immunity} alt="Gag Immunity" />
                                </td>
                                <td>
                                    Cogs are immune to Sound, Throw, Squirt, and Drop gags.
                                </td>
                                <td>
                                    Witness Protection (The Office Clerk)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Expendable
                                </td>
                                <td>
                                    <img src={Statuseffect_expendable} alt="Expendable" />
                                </td>
                                <td>
                                    Virtual Skelecogs explode when the Supervisor Cog is defeated.
                                </td>
                                <td>
                                    Virtual Skelecogs are summoned by The Office Clerk after cogs are defeated.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Delayed Damage
                                </td>
                                <td>
                                    <img src={Statuseffect_delaydmg} alt="Delayed Damage" />
                                </td>
                                <td>
                                    Toons are dealt a large amount of damage after 5 rounds,
                                    which decreases with each Cog defeated.
                                </td>
                                <td>
                                    Setting Par (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Extra Turn
                                </td>
                                <td>
                                    <img src={Statuseffect_extraturn} alt="Extra Turn" />
                                </td>
                                <td>
                                    An extra attack is given to the Supervisor Cog for each Cog defeated.
                                </td>
                                <td>
                                    Penalty Stroke (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Defense Down
                                </td>
                                <td>
                                    <img src={Statuseffect_defensedown} alt="Defense Down" />
                                </td>
                                <td>
                                    Cogs take increased damage from gags.
                                </td>
                                <td>
                                    Penalty Stroke (The Club President)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Off Limits
                                </td>
                                <td>
                                    <img src={Statuseffect_offlimits} alt="Off Limits" />
                                </td>
                                <td>
                                    The Supervisor Cog cannot be targeted.
                                </td>
                                <td>
                                    Presidential Protection (The Club President)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="boiler"></span>
                    <h2>The Boiler Only</h2>
                    <p>
                        These status effects are exclusive to The Boiler in Sellbot Field Offices.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Status Effect
                                </th>
                                <th>
                                    Icon
                                </th>
                                <th style={{ width: 60 + "%" }}>
                                    Description
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Fired Up
                                </td>
                                <td>
                                    <img src={Statuseffect_firedup} alt="Fired Up" />
                                </td>
                                <td>
                                    The Boiler's attacks, as well as gags used against them,
                                    deal 50% additional damage. Cogs receive 20% of Damage Up,
                                    which increases by 10% each time Fired Up begins.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Defensive Strategy
                                </td>
                                <td>
                                    <img src={Statuseffect_defensivestrategy} alt="Defensive Strategy" />
                                </td>
                                <td>
                                    The Boiler retaliates if they are damaged by gags and performs
                                    certain special moves that benefit the Cogs. Additional Cogs
                                    enter the battle until The Boiler reverts to being Fired Up.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Meltdown
                                </td>
                                <td>
                                    <img src={Statuseffect_meltdown} alt="Meltdown" />
                                </td>
                                <td>
                                    The Boiler's "last leg" phase once their health reaches the red bar where they become permanently Fired Up and perform most of their attacks and special moves (excluding Stamp of Disapproval). Additional Cogs enter the battle until The Boiler is defeated.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <PanelNavigation links={panelNavigationLinks} />
                </div>
            </div>
        );
    }
}

export default StatusEffects;
