import React from "react";
import ToontownCentral from "../components/neighborhoods/ToontownCentral";
import DonaldsDock from "../components/neighborhoods/DonaldsDock";
import DaisysGarden from "../components/neighborhoods/DaisysGarden";
import MinniesMelodyland from "../components/neighborhoods/MinniesMelodyland";
import TheBrrrgh from "../components/neighborhoods/TheBrrrgh";
import DonaldsDreamland from "../components/neighborhoods/DonaldsDreamland";
import ChipNDalesAcornAcres from "../components/neighborhoods/ChipNDalesAcornAcres";
import Ad from "../components/Ad";

class Neighborhoods extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "ttc",
            Content: null
        };
    }

    componentDidMount() {
        this.loadContent(this.state.activeTab);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeTab !== this.state.activeTab) {
            this.loadContent(this.state.activeTab);
        }
    }

    switchTab(tab) {
        this.setState({ activeTab: tab });
    }

    loadContent(tab) {
        switch (tab) {
            case "ttc":
                this.setState({
                    Content: <ToontownCentral />
                });
                break;
            case "dock":
                this.setState({
                    Content: <DonaldsDock />
                });
                break;
            case "dg":
                this.setState({
                    Content: <DaisysGarden />
                });
                break;
            case "mml":
                this.setState({
                    Content: <MinniesMelodyland />
                });
                break;
            case "brrrgh":
                this.setState({
                    Content: <TheBrrrgh />
                });
                break;
            case "dreamland":
                this.setState({
                    Content: <DonaldsDreamland />
                });
                break;
            case "chipndale":
                this.setState({
                    Content: <ChipNDalesAcornAcres />
                });
                break;
            default:
                this.setState({
                    Content: <ToontownCentral />
                });
        }
    }

    render() {
        let panelNav = (
            <div className="panel-nav web-button-nav">
                <button
                    name="ttc"
                    onClick={() => this.switchTab("ttc")}
                    style={
                        this.state.activeTab === "ttc"
                            ? { background: "red" }
                            : null
                    }
                >
                    Toontown Central
                </button>
                <button
                    name="dock"
                    onClick={() => this.switchTab("dock")}
                    style={
                        this.state.activeTab === "dock"
                            ? { background: "red" }
                            : null
                    }
                >
                    Donald's Dock
                </button>
                <button
                    name="dg"
                    onClick={() => this.switchTab("dg")}
                    style={
                        this.state.activeTab === "dg"
                            ? { background: "red" }
                            : null
                    }
                >
                    Daisy's Garden
                </button>
                <button
                    name="mml"
                    onClick={() => this.switchTab("mml")}
                    style={
                        this.state.activeTab === "mml"
                            ? { background: "red" }
                            : null
                    }
                >
                    Minnie's Melodyland
                </button>
                <button
                    name="brrrgh"
                    onClick={() => this.switchTab("brrrgh")}
                    style={
                        this.state.activeTab === "brrrgh"
                            ? { background: "red" }
                            : null
                    }
                >
                    The Brrrgh
                </button>
                <button
                    name="dreamland"
                    onClick={() => this.switchTab("dreamland")}
                    style={
                        this.state.activeTab === "dreamland"
                            ? { background: "red" }
                            : null
                    }
                >
                    Donald's Dreamland
                </button>
                <button
                    name="chipndale"
                    onClick={() => this.switchTab("chipndale")}
                    style={
                        this.state.activeTab === "chipndale"
                            ? { background: "red" }
                            : null
                    }
                >
                    Chip N' Dales Acorn Acres
                </button>

            </div>
        );

        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Neighborhoods</h1>
                    <p>
                        Toontown is compromised of neighborhoods, which is a
                        governing area of Toontown with its own theme, puns,
                        streets, and a playground. There are six main
                        neighborhoods where toons will complete toontasks. There
                        are an additional two neighborhoods that are
                        recreational and are mainly used for activities such as
                        racing or golfing.
                    </p>
                    <p>
                        Select a neighborhood from the list below to learn more
                        about that area of Toontown.
                    </p>
                    {panelNav}
                    <div className="mobile-button-nav">
                        <select
                            value={this.state.activeTab}
                            onChange={(event) =>
                                this.switchTab(event.target.value)
                            }
                        >
                            <option value="ttc">Toontown Central</option>
                            <option value="dock">Donald's Dock</option>
                            <option value="dg">Daisy's Garden</option>
                            <option value="mml">Minnie's Melodyland</option>
                            <option value="brrrgh">The Brrrgh</option>
                            <option value="dreamland">
                                Donald's Dreamland
                            </option>
                            <option value="chipndale">
                                Chip N' Dales Acorn Acres
                            </option>
                        </select>
                    </div>
                    <br />
                    <Ad dataAdSlot="8689384557" />
                    <br />
                    {this.state.Content}
                    <span className="web-button-nav">
                        <br />
                    </span>
                    <br />
                    {panelNav}
                    <span className="web-button-nav">
                        <br />
                    </span>
                    <Ad dataAdSlot="1733984228" />
                </div>
            </div>
        );
    }
}

export default Neighborhoods;
