import React from "react";
import "../../scss/list.scss";
import balloonfish from "../../assets/images/fish/balloonfish.png";
import starfish from "../../assets/images/fish/starfish.png";
import jellyfish from "../../assets/images/fish/jellyfish.png";
import catfish from "../../assets/images/fish/catfish.png";
import clownfish from "../../assets/images/fish/clownfish.png";
import dog from "../../assets/images/fish/dog.png";
import amoreeel from "../../assets/images/fish/amoreeel.png";
import nurseshark from "../../assets/images/fish/nurseshark.png";
import kingcrab from "../../assets/images/fish/kingcrab.png";
import seahorse from "../../assets/images/fish/seahorse.png";
import poolshark from "../../assets/images/fish/poolshark.png";
import cuttthroat from "../../assets/images/fish/cuttthroat.png";
import pianofish from "../../assets/images/fish/pianofish.png";
import holeymackerel from "../../assets/images/fish/holeymackerel.png";
import devilray from "../../assets/images/fish/devilray.png";
import moonfish from "../../assets/images/fish/moonfish.png";
import bearacuda from "../../assets/images/fish/bearacuda.png";
import frozenfish from "../../assets/images/fish/frozenfish.png";
import jellybeanJar from "../../assets/images/jellybean_jar.png";

class Species extends React.Component {
    render() {
        let tableHeader = (
            <tr>
                <th>Species</th>
                <th>Pond</th>
                <th>Rod</th>
                <th>Rarity</th>
                <th>
                    <img src={jellybeanJar} alt="Jellybeans" />
                </th>
            </tr>
        );

        return (
            <div id="animate" className="list">
                <div className="list-navigation">
                    <a href="#balloon">Balloon Fish</a> |{" "}
                    <a href="#jellyfish">Jellyfish</a> |{" "}
                    <a href="#catfish">Cat Fish</a> |{" "}
                    <a href="#clownfish">Clown Fish</a> |{" "}
                    <a href="#frozen">Frozen Fish</a> |{" "}
                    <a href="#starfish">Star Fish</a> |{" "}
                    <a href="#holey">Holey Mackerel</a> |{" "}
                    <a href="#dogfish">Dog Fish</a> |{" "}
                    <a href="#devil">Devil Ray</a>
                    <br />
                    <a href="#eel">Amore Eel</a> |{" "}
                    <a href="#shark">Nurse Shark</a> |{" "}
                    <a href="#crab">King Crab</a> |{" "}
                    <a href="#moonfish">Moon Fish</a> |{" "}
                    <a href="#seahorse">Sea Horse</a> |{" "}
                    <a href="#poolshark">Pool Shark</a> |{" "}
                    <a href="#acuda">Bear Acuda</a> |{" "}
                    <a href="#trout">Cutthroat Trout</a> |{" "}
                    <a href="#piano">Piano Tuna</a>
                </div>
                <div className="filtering">
                    <button name="species" className="active">
                        By Fish
                    </button>
                    <button
                        name="rod"
                        type="button"
                        onClick={() => {
                            this.props.action(false, false);
                        }}
                    >
                        By Rod
                    </button>
                    <button
                        name="pond"
                        type="button"
                        onClick={() => {
                            this.props.action(false, true);
                        }}
                    >
                        By Pond
                    </button>
                </div>
                <span className="anchor" id="balloon"></span>
                <h2>Balloon Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={balloonfish}
                        alt="Balloon fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Balloon Fish</td>
                                <td>All Ponds</td>
                                <td>Twig</td>
                                <td><span style={{ color: "#285b9f" }}>VC (1)</span></td>
                                <td>2 - 7</td>
                            </tr>
                            <tr>
                                <td>Hot Air Balloon Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>Weather Balloon Fish</td>
                                <td>
                                    Punchline Place
                                    <br />
                                    <br />
                                    The Brrrgh
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>17- 19</td>
                            </tr>
                            <tr>
                                <td>Water Balloon Fish</td>
                                <td>
                                    Silly Street
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>9 - 11</td>
                            </tr>
                            <tr>
                                <td>Red Balloon Fish</td>
                                <td>
                                    Loopy Lane
                                    <br />
                                    <br />
                                    Toontown Central
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>5 - 7</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="jellyfish"></span>
                <h2>Jellyfish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={jellyfish}
                        alt="Jellyfish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Peanut Butter & Jellyfish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    Estate
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>5 - 8</td>
                            </tr>
                            <tr>
                                <td>Grape PB&amp;J Fish</td>
                                <td>
                                    The Brrrgh
                                    <br />
                                    <br />
                                    Estate
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>8 - 11</td>
                            </tr>
                            <tr>
                                <td>Crunchy PB&J Fish</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>12 - 14</td>
                            </tr>
                            <tr>
                                <td>Strawberry PB&amp;J Fish</td>
                                <td>
                                    Donald's Dreamland
                                    <br />
                                    <br />
                                    Estate
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                                <td>16 - 19</td>
                            </tr>
                            <tr>
                                <td>Concord Grape PB&J Fish</td>
                                <td>
                                    The Brrrgh
                                    <br />
                                    <br />
                                    Donald's Dreamland
                                </td>
                                <td>Twig</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                                <td>44 - 46</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="catfish"></span>
                <h2>Cat Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={catfish}
                        alt="Catfish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Cat Fish</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span>
                                </td>
                                <td>3 - 7</td>
                            </tr>
                            <tr>
                                <td>Siamese Cat Fish</td>
                                <td>
                                    Elm Street
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "brown" }}>ER (9)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                                <td>39 - 40</td>
                            </tr>
                            <tr>
                                <td>Alley Cat Fish</td>
                                <td>Lullaby Lane</td>
                                <td>Bamboo</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                                <td>15 - 19</td>
                            </tr>
                            <tr>
                                <td>Tabby Cat Fish</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>6 - 11</td>
                            </tr>
                            <tr>
                                <td>Tom Cat Fish</td>
                                <td>
                                    Donald's Dreamland
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>8 - 12</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="clownfish"></span>
                <h2>Clown Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={clownfish}
                        alt="Clown fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Clown Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span>
                                </td>
                                <td>3 - 7</td>
                            </tr>
                            <tr>
                                <td>Sad Clown Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>13 - 17</td>
                            </tr>
                            <tr>
                                <td>Party Clown Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>6 - 10</td>
                            </tr>
                            <tr>
                                <td>Circus Clown Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    Minnie's Melodyland
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                                <td>21 - 27</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="frozen"></span>
                <h2>Frozen Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={frozenfish}
                        alt="Frozen fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Frozen Fish</td>
                                <td>The Brrrgh</td>
                                <td>Bamboo</td>
                                <td><span style={{ color: "#285b9f" }}>VC (1)</span></td>
                                <td>7 - 10</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="starfish"></span>
                <h2>Star Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={starfish}
                        alt="Star fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Star Fish</td>
                                <td>All Ponds</td>
                                <td>Twig</td>
                                <td><span style={{ color: "#285b9f" }}>VC (1)</span></td>
                                <td>2 - 8</td>
                            </tr>
                            <tr>
                                <td>Five Star Fish</td>
                                <td>
                                    Minnie's Melodyland
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>5 - 8</td>
                            </tr>
                            <tr>
                                <td>Rock Star Fish</td>
                                <td>
                                    Minnie's Melodyland
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>19 - 22</td>
                            </tr>
                            <tr>
                                <td>Shining Star Fish</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                                <td>26 - 32</td>
                            </tr>
                            <tr>
                                <td>All Star Fish</td>
                                <td>
                                    Toontown Central
                                    <br />
                                    <br />
                                    Donald's Dock
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                                <td>43 - 46</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="holey"></span>
                <h2>Holey Mackerel</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={holeymackerel}
                        alt="Holey Mackerel"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Holey Mackerel</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    Toontown Central
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "brown" }}>ER (9)</span><br />
                                    <br /><span style={{ color: "maroon" }}>UR (10)</span><br />
                                    <br /><span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                                <td>41 - 43</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="dogfish"></span>
                <h2>Dog Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={dog}
                        alt="Dog fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Dog Fish</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span>
                                </td>
                                <td>7 - 13</td>
                            </tr>
                            <tr>
                                <td>Bull Dog Fish</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Gold</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                                <td>34 - 35</td>
                            </tr>
                            <tr>
                                <td>Hot Dog Fish</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>16 - 19</td>
                            </tr>
                            <tr>
                                <td>Dalmatian Dog Fish</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>13 - 16</td>
                            </tr>
                            <tr>
                                <td>Puppy Dog Fish</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>5</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="devil"></span>
                <h2>Devil Ray</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={devilray}
                        alt="Devil Ray"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Devil Ray</td>
                                <td>
                                    Donald's Dreamland
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "brown" }}>ER (9)</span><br />
                                    <br /><span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                                <td>44 - 58</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="eel"></span>
                <h2>Amore Eel</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={amoreeel}
                        alt="Amore eel"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Amore Eel</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Estate
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>3 - 6</td>
                            </tr>
                            <tr>
                                <td>Electric Amore Eel</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Estate
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>9 - 11</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="shark"></span>
                <h2>Nurse Shark</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={nurseshark}
                        alt="Nurse Shark"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Nurse Shark</td>
                                <td>
                                    Minnie's Melodyland
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>18 - 24</td>
                            </tr>
                            <tr>
                                <td>Clara Nurse Shark</td>
                                <td>
                                    Baritone Boulevard
                                    <br />
                                    <br />
                                    Minnie's Melodyland
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                                <td>28 - 37</td>
                            </tr>
                            <tr>
                                <td>Florence Nurse Shark</td>
                                <td>
                                    Tenor Terrace
                                    <br />
                                    <br />
                                    Minnie's Melodyland
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#F62217" }}>VR (8)</span><br />
                                    <br /><span style={{ color: "brown" }}>ER (9)</span>
                                </td>
                                <td>33 - 39</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="crab"></span>
                <h2>King Crab</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={kingcrab}
                        alt="King crab"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>King Crab</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>5 - 10</td>
                            </tr>
                            <tr>
                                <td>Alaskan King Crab</td>
                                <td>The Brrrgh</td>
                                <td>Bamboo</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                                <td>29 - 31</td>
                            </tr>
                            <tr>
                                <td>Old King Crab</td>
                                <td>Lighthouse Lane</td>
                                <td>Twig</td>
                                <td><span style={{ color: "#F62217" }}>VR (8)</span></td>
                                <td>33 - 36</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="moonfish"></span>
                <h2>Moon Fish</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={moonfish}
                        alt="Moon fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Moon Fish</td>
                                <td>Donald's Dreamland</td>
                                <td>Twig</td>
                                <td><span style={{ color: "#285b9f" }}>VC (1)</span></td>
                                <td>4 - 6</td>
                            </tr>
                            <tr>
                                <td>Full Moon Fish</td>
                                <td>Donald's Dreamland</td>
                                <td>Steel</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                                <td>53 - 56</td>
                            </tr>
                            <tr>
                                <td>Crescent Moon Fish</td>
                                <td>Lullaby Lane</td>
                                <td>Twig</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                                <td>21 - 24</td>
                            </tr>
                            <tr>
                                <td>New Moon Fish</td>
                                <td>Donald's Dreamland</td>
                                <td>Twig</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>Half Moon Fish</td>
                                <td>Lullaby Lane</td>
                                <td>Bamboo</td>
                                <td><span style={{ color: "#F62217" }}>VR (8)</span></td>
                                <td>36 - 37</td>
                            </tr>
                            <tr>
                                <td>Harvest Moon Fish</td>
                                <td>
                                    Donald's Dreamland
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Hardwood</td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>18 - 21</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="seahorse"></span>
                <h2>Sea Horse</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={seahorse}
                        alt="Sea horse"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Sea Horse</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Hardwood</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                                <td>13 - 19</td>
                            </tr>
                            <tr>
                                <td>Rocking Sea Horse</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Steel</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                                <td>18 - 21</td>
                            </tr>
                            <tr>
                                <td>Clydesdale Sea Horse</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Steel</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>26 - 30</td>
                            </tr>
                            <tr>
                                <td>Arabian Sea Horse</td>
                                <td>
                                    Estate
                                    <br />
                                    <br />
                                    Daisy Gardens
                                </td>
                                <td>Steel</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                                <td>36 - 40</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="poolshark"></span>
                <h2>Pool Shark</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={poolshark}
                        alt="Pool shark"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Pool Shark</td>
                                <td>All Ponds</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                                <td>14 - 15</td>
                            </tr>
                            <tr>
                                <td>Kiddie Pool Shark</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>21 - 24</td>
                            </tr>
                            <tr>
                                <td>Swimming Pool Shark</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                                <td>26 - 29</td>
                            </tr>
                            <tr>
                                <td>Olympic Pool Shark</td>
                                <td>
                                    Daisy Gardens
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Bamboo</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                                <td>31 - 37</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="acuda"></span>
                <h2>Bear Acuda</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={bearacuda}
                        alt="Bear acuda"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Brown Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "#285b9f" }}>VC (2)</span></td>
                                <td>11 - 18</td>
                            </tr>
                            <tr>
                                <td>Black Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                                <td>15 - 21</td>
                            </tr>
                            <tr>
                                <td>Koala Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                                <td>18 - 26</td>
                            </tr>
                            <tr>
                                <td>Honey Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "green" }}>C (5)</span></td>
                                <td>23 - 29</td>
                            </tr>
                            <tr>
                                <td>Polar Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                                <td>29 - 35</td>
                            </tr>
                            <tr>
                                <td>Panda Bear Acuda</td>
                                <td>The Brrrgh</td>
                                <td>Steel</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                                <td>36 - 40</td>
                            </tr>
                            <tr>
                                <td>Kodiac Bear Acuda</td>
                                <td>
                                    Sleet Street
                                    <br />
                                    <br />
                                    The Brrrgh
                                </td>
                                <td>Steel</td>
                                <td>
                                    <span style={{ color: "#F62217" }}>VR (8)</span><br />
                                    <br /><span style={{ color: "brown" }}>ER (9)</span>
                                </td>
                                <td>41 - 45</td>
                            </tr>
                            <tr>
                                <td>Grizzly Bear Acuda</td>
                                <td>
                                    The Brrrgh
                                    <br />
                                    <br />
                                    Walrus Way
                                </td>
                                <td>Steel</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                                <td>55 - 58</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="trout"></span>
                <h2>Cutthroat Trout</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={cuttthroat}
                        alt="Cutthtoat trout"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Cutthroat Trout</td>
                                <td>
                                    Donald's Dock
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                                <td>5 - 11</td>
                            </tr>
                            <tr>
                                <td>Captain Cutthroat Trout</td>
                                <td>
                                    Barnacle Boulevard
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                                <td>23 - 27</td>
                            </tr>
                            <tr>
                                <td>Scurvy Cutthroat Trout</td>
                                <td>
                                    Seaweed Street
                                    <br />
                                    <br />
                                    Donald's Dock
                                </td>
                                <td>Twig</td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                                <td>28 - 32</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="piano"></span>
                <h2>Piano Tuna</h2>
                <ul>
                    <img
                        className="list-fish"
                        src={pianofish}
                        alt="Piano fish"
                    />
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>Piano Tuna</td>
                                <td>
                                    Minnie's Melodyland
                                    <br />
                                    <br />
                                    All Ponds
                                </td>
                                <td>Steel</td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                                <td>25 - 28</td>
                            </tr>
                            <tr>
                                <td>Grand Piano Tuna</td>
                                <td>
                                    Minnie's Melodyland
                                    <br />
                                    <br />
                                    Alto Avenue
                                </td>
                                <td>Steel</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                                <td>55 - 59</td>
                            </tr>
                            <tr>
                                <td>Baby Grand Piano Tuna</td>
                                <td>
                                    Tenor Terrace
                                    <br />
                                    <br />
                                    Minnie's Melodyland
                                </td>
                                <td>Hardwood</td>
                                <td>
                                    <span style={{ color: "brown" }}>ER (9)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                                <td>46 - 50</td>
                            </tr>
                            <tr>
                                <td>Upright Piano Tuna</td>
                                <td>Minnie's Melodyland</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                                <td>29 - 34</td>
                            </tr>
                            <tr>
                                <td>Player Piano Tuna</td>
                                <td>Minnie's Melodyland</td>
                                <td>Hardwood</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                                <td>29 - 39</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
            </div>
        );
    }
}

export default Species;
