import React from "react";
import { Link } from "react-router-dom";
import gag_cross_example from "../assets/images/gag_cross_example.png";
import toonhq_group from "../assets/images/toonhq_group.PNG";
import Street_Map_Silly_Street from "../assets/images/Street_Map_Silly_Street.png";
import Street_Map_Pajama_Place from "../assets/images/Street_Map_Pajama_Place.png";
import Street_Map_Polar_Place from "../assets/images/Street_Map_Polar_Place.png";
import hibernation_vacations from "../assets/images/hibernation_vacations.PNG";
import resistance_salute from "../assets/images/resistance_salute.png";
import mole_stomp_strat from "../assets/images/mole_stomp_strat.png";
import cfo_safehelmet from "../assets/images/cfo_safehelmet.PNG";
import vip_card from "../assets/images/cogs/bossbots/vip_card.png";
import black_cat from "../assets/images/black_cat.PNG";
import polar_bear from "../assets/images/polar_bear.PNG";
import red_nose_reindeer from "../assets/images/red_nose_reindeer.PNG";
import vague_tt from "../assets/images/vague_tt.PNG";
import sillymeter_fishteam from "../assets/images/sillymeter_fishteam.png";
import Ad from "../components/Ad";

class Toontips extends React.Component {
    render() {
        return (
            <>
                <div className="panel">
                    <div className="panel-content">
                        <h1>Toon Tips</h1>
                        <p>
                            There are a lot of ins and outs to playing Toontown,
                            and every player has their own way of doing things.
                            Over the years, I have discovered some simple,
                            useful tips that enhance the gameplay experience.
                            Some tips include universal toon strategy and some
                            tips include personal preferences. If there is a tip
                            that you do not see here and you think that tip
                            would helpful, please feel free to share it with me{" "}
                            <Link to="/contact">here</Link>!
                        </p>
                        <h2>Contents</h2>
                        <ol>
                            <li>
                                <a href="#1">
                                    What to do in a Cog Battle
                                </a>
                            </li>
                            <li>
                                <a href="#2">Where to Find Toons</a>
                            </li>
                            <li>
                                <a href="#3">
                                    Playground &amp; Street Secrets
                                </a>
                            </li>
                            <li>
                                <a href="#4">
                                    Gag Experience Multipliers
                                </a>
                            </li>
                            <li>
                                <a href="#5">
                                    Picking the Right Toontasks
                                </a>
                            </li>
                            <li>
                                <a href="#6">
                                    Pointers for Sellbot HQ
                                </a>
                            </li>
                            <li>
                                <a href="#7">Cashbot HQ Strategy</a>
                            </li>
                            <li>
                                <a href="#8">
                                    Helpful Tips for Lawbot HQ
                                </a>
                            </li>
                            <li>
                                <a href="#9">
                                    Bossbot HQ Survival Tips
                                </a>
                            </li>
                            <li>
                                <a href="#10">
                                    Notes on Organic Gag Tracks
                                </a>
                            </li>
                            <li>
                                <a href="#11">
                                    Things to Think About When Fishing
                                </a>
                            </li>
                            <li>
                                <a href="#12">
                                    Special Holiday Toons
                                </a>
                            </li>
                            <li>
                                <a href="#13">
                                    Surviving Sellbot Field Offices
                                </a>
                            </li>
                        </ol>
                        <br />
                        <Ad dataAdSlot="8689384557" />
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="1"></span>
                        <details>
                            <summary>What to do in a Cog Battle</summary>
                            <h2>Gag Crossing</h2>
                            <p>
                                Try not to cross over toons when choosing cogs
                                to hit. Meaning, if you are positioned on the
                                left side of the battle, hit the cogs on the
                                left side. Why? Crossing can often cause
                                confusion between toons which can then lead to
                                one cog being over hit or not being hit at all.
                                This is especially helpful with game lag and
                                countdown clock pressure. Of course, sometimes
                                this method is not always possible, but do your
                                best to choose cogs wisely!
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 300 + "px" }}
                                src={gag_cross_example}
                                alt="Gag crossing example"
                            />
                            <h2>The 3 Piano Trick</h2>
                            <p>
                                Ever join a cog battle in a Cog Mint, DA Office,
                                or Cog Golf Course and one cog is taking awhile
                                to join the battle? This is actually a great
                                situation if at least 3 toons have maxed drop.
                                If there are 3 cogs in the battle, one toon can
                                use an aoogah or elephant trunk while the other
                                3 toons each hit a separate cog with a piano.
                                This helps save sound gags for long cog
                                facilities and it is just convenient!
                            </p>
                            <p>
                                Please note, not every group will utilize this
                                strategy, but it is helpful to be aware of its
                                potential.
                            </p>
                            <h2>Sound vs. Lure</h2>
                            <p>
                                I am sure every toon has seen the SpeedChat
                                phrase "Don't use sound on lured cogs!". This is
                                a very important tip, because if violated, it
                                could jeopardize the whole cog battle. If, for
                                example, sound is used on a round of four lured
                                cogs, then all of those cogs get pushed back and
                                will attack the toons. Sound also does not have
                                jump back bonus damage and it will remove any
                                possible jump back damage given by gags that
                                come after sound. No good!
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="2"></span>
                        <details>
                            <summary>Where to Find Toons</summary>
                            <h2>Toon HQs Group Tracker</h2>
                            <p>
                                Sometimes it can be hard to find other toons to
                                help defeat a cog building, to join a boss
                                battle, or to go racing with; especially if no
                                toon friends are online. Instead of waiting for
                                hours on end for maybe one toon to help, check
                                out{" "}
                                <a
                                    href="https://toonhq.org/groups/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Toon HQs Group Tracker
                                </a>
                                . This tracker allows toons to easily find other
                                toons to group up with in real time! A toon can
                                either join an existing group or create their
                                own. No more begging for help on the streets!
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 225 + "px" }}
                                src={toonhq_group}
                                alt="Toon HQ Group tracker screenshot"
                            />
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="3"></span>
                        <details>
                            <summary>Playground &amp; Street Secrets</summary>
                            <h2>Street Shortcuts</h2>
                            <p>
                                Running to Minnie's Melodyland, Cashbot HQ, or
                                Lawbot HQ by foot is easy with shortcuts on the
                                streets that lead to those playgrounds. Quickly
                                get to Minnie's Melodyland from Toontown Central
                                through the shortcut located at the halfway
                                point of Silly Street (left). Speedily get to
                                Cashbot HQ by using the shortcut located at the
                                beginning of Pajama Place (middle). Get to
                                Lawbot HQ so fast it seems as if you teleported
                                through the shortcut located at the beginning of
                                Polar Place (right).
                            </p>
                            <div className="row row-images">
                                <div className="column">
                                    <img
                                        className="content-img"
                                        style={{ height: 250 + "px" }}
                                        src={Street_Map_Silly_Street}
                                        alt="Silly Street map"
                                    />
                                </div>
                                <div className="column">
                                    <img
                                        className="content-img"
                                        style={{ height: 250 + "px" }}
                                        src={Street_Map_Pajama_Place}
                                        alt="Pajama Place map"
                                    />
                                </div>
                                <div className="column">
                                    <img
                                        className="content-img"
                                        style={{ height: 250 + "px" }}
                                        src={Street_Map_Polar_Place}
                                        alt="Polar Place map"
                                    />
                                </div>
                            </div>
                            <h2>Polar Toon</h2>
                            <p>
                                Have you ever wanted your toon to be giant &amp;
                                colorless (white). The Polar Toon affect
                                temporarily makes your toon giant and white. To
                                experience this, a toon can visit Paula Behr at
                                Hibernation Vacations on Polar Place in The
                                Brrrgh. There, a toon must say "Howdy!" to Paula
                                using SpeedChat. This affect only works in the
                                Brrrgh and is NOT permanent.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 225 + "px" }}
                                src={hibernation_vacations}
                                alt="Hibernation Vacations"
                            />
                            <h2>Resistance Salute</h2>
                            <p>
                                Ever see other toons around Toontown yelling
                                "Toons of the world unite!" and jumping up with
                                a fist in the air? That animation is called the
                                Resistance Salute. This is not an animation a
                                toon can purchase like Bow or Dance. Toon musts
                                visit Whispering Willow at Talking in Your Sleep
                                Voice Training on Pajama Place in Donald's
                                Dreamland. There, a toon must ask Willow "Would
                                you like some help?" using SpeedChat. Once
                                completed, the Resistance Salute animation can
                                be found under the Emotions tab in the Speedchat
                                menu.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 225 + "px" }}
                                src={resistance_salute}
                                alt="Resistance Salute animation"
                            />
                            <h2>Goofy Speedway Teleport Trick</h2>
                            <p>
                                New toon? Go to Goofy's Speedway (accessible in
                                Toontown Central near the Clothing Shop) to gain
                                teleport access. A toon can then get back to
                                Toontown Central quickly from anywhere in
                                Toontown by teleporting to Goofy Speedway and
                                using the tunnel to the playground.
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="4"></span>
                        <details>
                            <summary>Gag Experience Multipliers</summary>
                            <h2>Cog HQ Bonus</h2>
                            <p>
                                For those who are used to Toontown Online or are
                                just unaware, Cog HQs give double XP! That means
                                twice the gag XP and twice the promotion XP in
                                half the time! Cog HQ bonuses are not affected
                                by district cog invasions.
                            </p>
                            <h2>Building Multipliers</h2>
                            <p>
                                To effectively and efficiently train gags,
                                defeating cog buildings during invasions is the
                                way to go. Not only do toons get +.5 added to
                                the experience multipler each floor they go up
                                (i.e. 1x, 1.5x, 2x...), during invasions, the
                                multipler is also doubled (i.e. 2x, 3x, 4x...)!
                                It is really easy to earn max gag experience
                                points for a gag track (300pts)! Learn more
                                about cogs and cog buildings{" "}
                                <Link to="/cogs">here</Link>!
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="5"></span>
                        <details>
                            <summary>Picking the Right Toontasks</summary>
                            <h2>Vague Toontasks</h2>
                            <p>
                                When progressing throughout the main storyline,
                                try to pick toontasks that are as vague as
                                possible. For example, "Defeat 50 cogs" or
                                "Defeat 25 Bossbots". With vague tasks like
                                these, a toon can go to invasion districts and
                                fight cogs while also satisfying toontask
                                requirements. This way a toon can gain gag
                                experience quicker while also working on
                                toontasks.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 200 + "px" }}
                                src={vague_tt}
                                alt="Vague Toontasks example"
                            />
                            <h2>Similar Toontasks</h2>
                            <p>
                                If you're a player that likes to multitask, try
                                to pick toontasks that relate to one another. If
                                a toon has a task they cannot avoid such as
                                "Recover 10 Postcards from Double Talkers", then
                                try to pick other tasks that would also be
                                satisfied by defeating Double Talkers. For
                                example, "Defeat 50 Lawbots".
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="6"></span>
                        <details>
                            <summary>Pointers for Sellbot HQ</summary>
                            <h2>Two Short Factories</h2>
                            <p>
                                Completing two short factories is quicker than
                                going through one long factory, assuming
                                everything goes accoridng to plan. Additionally,
                                two shorts will reward a toon with 960 merits,
                                which is 184 more merits compared to the long
                                route reward. This is very helpful for mid- to
                                high-level suits.
                            </p>
                            <h2>Factory Restock Barrels</h2>
                            <h4>The Lava Room</h4>
                            <p>
                                Most toons stop here when following the long
                                route in a factory, usually to replenish sound
                                gags.
                            </p>
                            <ul>
                                <li>Squirt restock barrel (fire hose)</li>
                                <li>Sound restock barrel (elephant trunk)</li>
                                <li>10 laff restock barrel</li>
                                <li>Two 25 jellybean restock barrels</li>
                            </ul>
                            <h4>The Paint Mixer Room</h4>
                            <ul>
                                <li>25 jellybeans restock barrel</li>
                                <li>Two Toon-up restock barrels</li>
                                <li>Throw restock barrel</li>
                                <li>Squirt restock barrel</li>
                            </ul>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="7"></span>
                        <details>
                            <summary>Cashbot HQ Strategy</summary>
                            <h2>Throwing Safes at the C.F.O.</h2>
                            <p>
                                During the final round of the C.F.O., when using
                                the crane, do not throw a safe at the C.F.O.
                                unless he is stunned. If a safe is thrown
                                without him being stunned, the C.F.O. will
                                obtain a helmet that will need to be knocked off
                                in order to deal more damage. To know if the
                                C.F.O. is stunned, the sound of an aoogah will
                                go off and C.F.O. will appear dizzy. In the
                                screenshot below, the C.F.O. is wearing a safe
                                helmet.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 250 + "px" }}
                                src={cfo_safehelmet}
                                alt="CFO with Safe Helmet on"
                            />
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="8"></span>
                        <details>
                            <summary>Helpful Tips for Lawbot HQ</summary>
                            <h2>DA Office Strategy</h2>
                            <p>
                                Under the assumption that all four toons in a DA
                                Office have sound, there is a certain
                                expectation for each of the battles on a floor.
                                During the first round on a floor, two toons
                                will use the fog horn and the other two will
                                either use an elephant trunk or an aoogah.
                                Typically, the toons who did not use a fog on
                                the previous floor will use it on the next
                                floor. During the second round, the cogs will be
                                lured and defeated individually. The second
                                round is a great battle to use an organic cake
                                on the level 11 cogs.
                            </p>
                            <p>
                                Usually, with a soundless toon present, the
                                first round will be lured. During the second
                                round, all toons with sound will use fog while
                                the soundless toons takes out the level 12
                                Skelecog. This occurs until toons run out of fog
                                horns.
                            </p>
                            <h2>Filling the Jury Seats with Toons</h2>
                            <p>
                                During the Chief Justice battle, round two
                                involves shooting familiar toons out of a cannon
                                to fill as many jury seats as possible. It might
                                be tempting to start shooting as soon as the
                                round starts, but this is not advantageous. It
                                is best to wait till 20 to 22 seconds left on
                                the clock to start shooting. Why? At this point,
                                all the cogs have sat down and toons can take
                                over while also not leaving a lot of time for
                                cogs to take back over the jury.
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="9"></span>
                        <details>
                            <summary>Bossbot HQ Survival Tips</summary>
                            <h2>Golf Course Maze</h2>
                            <p>
                                The Golf Course mazes seem impossible,
                                especially with little experience. But, toons
                                can see what direction to go based on the
                                textures of the hedges. If there are vines,
                                toons need to go left. If there are no vines,
                                toons should go right. There are also colored
                                flags that hint how far a toon is from the end
                                of the maze.
                            </p>
                            <h2>The Mole Stomp</h2>
                            <p>
                                In a Cog Golf Course, one of the first obstacles
                                or puzzles encountered is the mole stomp. There
                                is a 6x6 grid of holes in the ground where moles
                                will pop up. An effective way to conquer this is
                                to divide the grid by four, so each toon in the
                                Golf Course gets their own 3x3 corner of the
                                grid. A toon is responsible for their own corner
                                and does not have to worry about the rest of the
                                grid. This makes for a quicker run and provides
                                a higher success rate.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 235 + "px" }}
                                src={mole_stomp_strat}
                                alt="Mole Stomp Quadrants"
                            />
                            <h2>Cog Golf Course Strategy</h2>
                            <p>
                                With reinforced plating, there is a standard
                                strategy to follow in these courses. On each
                                hole, the first round of cogs is typically
                                defeated by one fog and three elephant trunks.
                                In the second battle, the strategy can go one
                                of two ways, depending on how much sound each
                                toon has in stock. If there is plenty of sound
                                to use, the first gag round will be lure with
                                TNT used on the v2.0 cog, with the remaining
                                toons passing. After this, sound like normal.
                                If sound needs to preserved, lure with TNT used
                                on the v2.0 cog, with the remaining toons using
                                gags to defeat the other cogs.
                            </p>
                            <p>
                                Please note that this strategy will vary depending
                                on how many toons in the course have the sound
                                gag track.
                            </p>
                            <h2>The First Round in the C.E.O.</h2>
                            <p>
                                Attention! The one fog rule is no more! With the
                                addition of Reinforced Plating on Version 2.0
                                cogs, the one fog rule is not very effective
                                anymore. Now toons lure the set of cogs if a
                                v2.0 cog is present, with a TNT stick used on
                                the v2.0 cog. Once the v2.0 outer suit is
                                destroyed or there is no v2.0 cog present, use
                                sound like normal.
                            </p>
                            <p>
                                Exceptions do apply to this tip! If the v2.0
                                cog level is low enough, sometimes a good sound
                                strategy will work to defeat the cogs in one
                                round.
                            </p>
                            <h2>The Second Round: Serving the Bossbots</h2>
                            <p>
                                In the second round of the C.E.O., toons will
                                act as Bossbot waiters. A good tip to follow is
                                to serve the Version 2.0 cogs first, so you and
                                your team will a few or none to battle in the
                                third round. You'd much rather fight the regular,
                                non-armoured cogs! Version 2.0 cogs can be
                                indentified in this round by their irregular
                                movements and V.I.P. cards placed in front of them
                                on the banquet tables, as shown below.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 240 + "px" }}
                                src={vip_card}
                                alt="Middle six golf course entrance"
                            />
                            <h2>When the C.E.O. says "Fore!"</h2>
                            <p>
                                When the C.E.O. yells "Fore!" jump off the table
                                after he swings and back up. He already targeted
                                each toons location, so if a toon moves, the
                                golf balls will miss them. This tip also works
                                when the C.E.O. targets you with gears.
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="10"></span>
                        <details>
                            <summary>Notes on Organic Gag Tracks</summary>
                            <h2>Organic Throw</h2>
                            <p>
                                Organic throw is pretty helpful in a lot of
                                situations. An organic cake deals 110 damage,
                                which can defeat a level 11 cog by itself if
                                that cog is lured. This is extremely useful in a
                                lot of situations, especially Lawbot DA offices.
                                Highly recommended!
                            </p>
                            <h3>Some Notable Advantages of Organic Throw</h3>
                            <ul>
                                <li>
                                    A level 5 cog can be defeated with an
                                    organic whole cream pie.
                                </li>
                                <li>
                                    A level 5 cog that is lured can be defeated
                                    with an organic whole fruit pie.
                                </li>
                                <li>
                                    A level 9 cog can be defeated with a organic
                                    Birthday Cake.
                                </li>
                                <li>
                                    A level 10 cog can be defeated with a
                                    organic Wedding Cake.
                                </li>
                                <li>
                                    A lured level 11 cog can be defeated with
                                    a organic Birthday Cake.
                                </li>
                            </ul>
                            <h2>Organic Sound</h2>
                            <p>
                                Organic sound is also extremely useful if used
                                strategically. It is very valuable in Cashbot HQ
                                and Sellbot HQ when another toon either does not
                                have maxed sound or does not have sound at all.
                                Also highly recommended!
                            </p>
                            <h3>Some Notable Advantages of Organic Sound</h3>
                            <ul>
                                <li>
                                    A [set of] level 9 cog(s) can be defeated
                                    with 4 organic elephant trunks.
                                </li>
                                <li>
                                    A [set of] level 11 cog(s) can be defeated
                                    with 2 organic fogs and a regular elephant
                                    trunk.
                                </li>
                                <li>
                                    A [set of] level 10 cog(s) can be defeated
                                    with 2 organic fogs OR 1 regular fog, 1
                                    regular elephant trunk, 1 organic elephant
                                    trunk, and 1 aoogah.
                                </li>
                                <li>
                                    A [set of] level 8 cog(s) can be defeated
                                    with 1 organic fog and a regular elephant
                                    trunk.
                                </li>
                                <li>
                                    A [set of] level 6 cog(s) can be defeated
                                    with 2 organic elephant trunks.
                                </li>
                            </ul>
                            <h2>Organic Lure</h2>
                            <p>
                                Organic lure gives a toon 10% more accuracy,
                                bringing it to Medium accuracy. This makes a pretty
                                impressive small magnet. This is helpful if a
                                toon is training their lure alone, until they
                                get the goggles. There is not much benefit to
                                organic lure after reaching level 6 or maxing
                                the gag track. When used, other gag tracks such
                                as trap and toon-up increase the accuracy of
                                lure.
                            </p>
                            <h2>Other Notable Organic Gag Advantages</h2>
                            <ul>
                                <li>
                                    A level 7 cog can be defeated with an
                                    organic Trapdoor.
                                </li>
                                <li>
                                    A level 12 cog can be defeated with an
                                    organic TNT stick.
                                </li>
                                <li>
                                    A level 12 v2.0 outer shell can be
                                    destroyed with an organic railroad.
                                </li>
                                <li>
                                    A level 10 cog that is lured can be defeated
                                    with an organic Storm Cloud.
                                </li>
                                <li>
                                    A [set of] level 9 cog(s) can be defeated
                                    with a organic Geyser.
                                </li>
                                <li>
                                    A level 12 cog can be defeated with an
                                    organic piano if stunned with an aoogah (or
                                    a gag of similar damage).
                                </li>
                            </ul>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="11"></span>
                        <details>
                            <summary>
                                Things to Think About When Fishing
                            </summary>
                            <h2>Catching New Species</h2>
                            <img
                                src={sillymeter_fishteam}
                                className="badge"
                                alt="Silly meter fish team badge"
                            />
                            <p>
                                Fishing is a very time consuming task and it
                                requires a lot of patience. Using the fishing
                                guide will help increase the chances of catching
                                fish species. Additionally, try to go fishing
                                when the Decrease Fish Rarity Silly Meter (badge
                                to the right) award is active! Most importantly,
                                do not give up.
                            </p>
                            <p>
                                To learn more about the Silly Meter, go{" "}
                                <Link to="/activities">here</Link>!
                            </p>
                            <h2>Earning Jellybeans Quickly</h2>
                            <p>
                                Have you ever found yourself in need of
                                jellybeans but you do not want to play trolley
                                games? Well do I have a solution for you! Go
                                fishing with whatever jellybeans are left in
                                your toons jar and keep selling fish until you
                                are content. Added bonus, this also helps
                                fishing progress.
                            </p>
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="12"></span>
                        <details>
                            <summary>Special Holiday Toons</summary>
                            <h2>The Black Cat</h2>
                            <p>
                                Did you see a black cat toon and wonder how to
                                make one? The black cat is a special toon color
                                that cats can apply on Halloween only. A player
                                must create a new cat toon of any color. Then
                                that new toon must go through Toontorial. When
                                talking to Flippy, before entering Toontown
                                Central, a toon must use the SpeedChat phrase
                                "Toontastic!". Then like magic, that toon is
                                black permanently!
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 200 + "px" }}
                                src={black_cat}
                                alt="Black cat toon"
                            />
                            <h2>The Polar Bear</h2>
                            <p>
                                Ever see a white bear roaming around Toontown?
                                That's a polar bear and can be made starting
                                December 24th until the end of Christmas day! A
                                player must create a new bear toon of any color.
                                Then that new toon must greet Paula Bear during
                                the Toontorial. After that, that toon is turned
                                white and is permanently a polar bear.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 200 + "px" }}
                                src={polar_bear}
                                alt="Polar bear toon"
                            />
                            <h2>The Red-Nosed Reindeer</h2>
                            <p>
                                Have a deer toon but want a red nose like
                                Rudolph? Well, I am happy to inform you that you
                                can have that! During the Twelve Days of Winter
                                event, starting December 21st, toons may visit
                                Holly Daze in the The Brrrgh to complete a
                                toontask for her. Upon completing the toontask,
                                toons will recieve a Santa's Bag backpack
                                accessory. If the toon is a deer, they can also
                                obtain a shiny red nose permanently! Unlike the
                                other specialty toons, a deer toon does not have
                                to be a new toon to get the red-nose.
                            </p>
                            <img
                                className="content-img"
                                style={{ height: 200 + "px" }}
                                src={red_nose_reindeer}
                                alt="Red Nosed Reindeer toon"
                            />
                        </details>
                    </div>
                </div>
                <div className="panel" style={{ marginTop: 0 }}>
                    <div className="panel-content">
                        <span className="anchor" id="13"></span>
                        <details>
                            <summary>Surviving Sellbot Field Offices</summary>
                            <h2>Saving Sound, Throw &amp; Squirt gags</h2>
                            <p>
                                Try to save fog horns, birthday cakes and storm
                                clouds for final The Boiler round. This is
                                especially important in 1-star or 2-star field
                                offices. In 3-star field offices, your team will
                                sometimes need a restock anyways, so do not
                                worry about this tip as much in those. Be sure
                                to communicate if you intend on using a restock!
                            </p>
                            <h2>No Sound, Lure or Toon-up</h2>
                            <p>
                                First of all, if sound, lure or toon-up is
                                locked, don't panic! Even if these gag tracks
                                are unavailable, you are free to use any SOS
                                toon or doodle call. So yes, Lil Oldman can save
                                the day! This means it would be wise to do some
                                SOS shopping to gain a good collection of
                                helpful cards and to do some doodle training.
                            </p>
                            <p>
                                Another option toons often forget about is that
                                they can use their healing remotes at any time.
                                So if your group is in desperate need for some
                                toon-up and the doodles are not listening, go
                                ahead an use a healing remote!
                            </p>
                            <h2>Cold Caller Cubicles</h2>
                            <h3>Team Coordination</h3>
                            <p>
                                Spread out! Try to coordinate with your team
                                on which quadrant of the map each toon will cover.
                                Calling out "bottom left", "bottom right", "top
                                left", or "top right" will help toons know who
                                is covering which spot. Generally, toons will
                                cover a quadrant based on their elevator
                                position. This will prevent unnecessary doubling
                                up and will help win the minigame in the time
                                allotted.
                            </p>
                            <h3>Destroy the Cold Callers First</h3>
                            <p>
                                Prioritize destroying Cold Callers early on.
                                These are the cogs that will continuously turn
                                the thermostats off, preventing your team from
                                moving on, so you want them out of your way as
                                soon as possible.
                            </p>
                            <h3>Collect all the Jokes</h3>
                            <p>
                                Do not hurry to elevator once it opens up. Try
                                to skate around and destroy all the cogs to
                                gather the most jokes possible. Extra jokes will
                                help unlock gag tracks and heal more laff for
                                the next cog battle round. That being said, get
                                to the elevator before time runs out! Not making
                                it to the elevator in time will cause you to
                                lose significant laff. If you do not have all
                                the jokes but there is less than 30 seconds
                                left, do not worry about it.
                            </p>
                            <h2>Mega-Mover Mazes</h2>
                            <h3>Team Coordination</h3>
                            <p>
                                Similar to the Cold Caller Cubicles, coordinate
                                with your team on which toon will cover which
                                quadrant. In this minigame, toons can team up in
                                pairs of two if they find that more effective.
                                Again, toons will cover a quadrant or half based
                                on their elevator position. The most important thing
                                to remember is to spread out! All four toons should
                                not be in the same quadrant, it is inefficient!
                            </p>
                            <h3>Identify the Nearest Water Cooler</h3>
                            <p>
                                Try to find the nearest water cooler in your
                                area. It is okay to move areas, but be sure to
                                find the closet water cooler there too. Staying
                                around the nearest cooler will save time running
                                and help avoid unnecessary obstacles. It also
                                helps collect and throw water balloons at a
                                faster rate.
                            </p>
                            <h3>Destroy the Fast Cogs</h3>
                            <p>
                                Of course, prioritize destroying the mega-movers
                                as that is what helps your team open the
                                elevator to move onto the next floor. But, it is
                                also extremely useful to focus on destroying the
                                faster moving cogs as they do more damage and
                                are more likely to bump into you.
                            </p>
                            <h3>Collect all the Jokes</h3>
                            <p>
                                Do not hurry to elevator once it opens up. Try
                                to run around and destroy all the cogs to gather
                                the most jokes possible. That being said, get to
                                the elevator before time runs out! Not making it
                                to the elevator in time will cause you to lose
                                significant laff. If you do not have all the
                                jokes but there is less than 30 seconds left, do
                                not worry about it.
                            </p>
                            <h2>The Boiler Battle</h2>
                            <h3>When to Attack The Boiler</h3>
                            <p>
                                Start off the first round with everyone using a
                                storm cloud. Although, some teams opt to use a
                                cream pie for the first hit. This is done to do
                                more damage later when the boiler is fired up.
                                This is most effective in one- and three-star
                                field offices.
                            </p>
                            <p>
                                In the second round, use four fog
                                horns to handle both The Boiler and the cogs
                                that just joined the battle. In a three star
                                Field Office, often a level 14+ cog will join
                                that this point. At least one toon in the battle
                                will have to use an Opera.
                            </p>
                            <p>
                                When The Boiler goes into the Fired Up phase,
                                damage him as much as possible. A good way to do
                                this is for each toon to use a birthday cake as
                                this deals the most damage out of any useable
                                gag. If you run out of birthday cakes, either
                                use a cream pie or a storm cloud as an
                                alternative.
                            </p>
                            <h3>Defense Strategy Phase</h3>
                            <p>
                                I'm sure you have heard this a bunch of times
                                now, but do not attack The Boiler when it is in
                                the Defense Strategy phase. The retaliation
                                damage to toons is not worth it, especially in a
                                three star Field Office. This means do not use
                                sound during this phase. This will hit all the
                                cogs, including The Boiler! Always remember, it
                                is okay to pass! You do NOT need to use a gag
                                for every round if it is not needed.
                            </p>
                            <p>
                                However, there are some moments when toons can
                                attack in this phase but this requires good
                                communication amongst participating toons. Toons
                                can attack if they know the damage dealt will
                                completely destroy The Boiler. Attacking The
                                Boiler can also work if all four cogs in the
                                battle are under healing control and all toons
                                have enough laff to take on the initial damage.
                                Note that this is not a common strategy and does
                                not work well in a 3-star Field Offices.
                            </p>
                            <h3>When to use Remote Controls</h3>
                            <p>
                                Often toons will use remote controls when The
                                Boiler enters the Defense Strategy phase. For
                                remote controls, target the higher level cogs
                                since they would typically deal the most damage
                                if they were not under control. The more toons
                                to use remote controls, the better. It is common
                                for each toon to use a remote, although not
                                necessary for lower level Field Offices.
                                Typically, toons aim to use healing remotes on the
                                higher level cogs and damage remotes on lower level cogs,
                                as applicable.
                            </p>
                            <p>
                                Remember to watch when a controller wears off.
                                Be aware that damage remotes do un-lure cogs.
                            </p>
                        </details>
                    </div>
                </div>
            </>
        );
    }
}

export default Toontips;
