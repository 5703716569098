import React from "react";
import "../../scss/list.scss";
import balloonfish from "../../assets/images/fish/balloonfish.png";
import starfish from "../../assets/images/fish/starfish.png";
import jellyfish from "../../assets/images/fish/jellyfish.png";
import catfish from "../../assets/images/fish/catfish.png";
import clownfish from "../../assets/images/fish/clownfish.png";
import dog from "../../assets/images/fish/dog.png";
import amoreeel from "../../assets/images/fish/amoreeel.png";
import nurseshark from "../../assets/images/fish/nurseshark.png";
import kingcrab from "../../assets/images/fish/kingcrab.png";
import seahorse from "../../assets/images/fish/seahorse.png";
import poolshark from "../../assets/images/fish/poolshark.png";
import cuttthroat from "../../assets/images/fish/cuttthroat.png";
import pianofish from "../../assets/images/fish/pianofish.png";
import holeymackerel from "../../assets/images/fish/holeymackerel.png";
import devilray from "../../assets/images/fish/devilray.png";
import moonfish from "../../assets/images/fish/moonfish.png";
import bearacuda from "../../assets/images/fish/bearacuda.png";
import frozenfish from "../../assets/images/fish/frozenfish.png";

class Pond extends React.Component {
    render() {
        let tableHeader = (
            <tr>
                <th>Fish Type</th>
                <th>Fish Species</th>
                <th>Rarity</th>
            </tr>
        );

        return (
            <div id="animate" className="list">
                <div className="list-navigation">
                    <a href="#allponds">All Ponds</a> |{" "}
                    <a href="#estate">Estate</a> |{" "}
                    <a href="#ttc">Toontown Central</a> |{" "}
                    <a href="#dock">Donald's Dock</a> |{" "}
                    <a href="#dg">Daisy Gardens</a> |{" "}
                    <a href="#minnies">Minnie's Melodyland</a> |{" "}
                    <a href="#brrrgh">The Brrrgh</a> |{" "}
                    <a href="#dreamland">Donald's Dreamland</a>
                </div>
                <div className="filtering">
                    <button
                        name="species"
                        type="button"
                        onClick={() => {
                            this.props.action(true, false);
                        }}
                    >
                        By Fish
                    </button>
                    <button
                        name="rod"
                        type="button"
                        onClick={() => {
                            this.props.action(false, false);
                        }}
                    >
                        By Rod
                    </button>
                    <button name="pond" className="active">
                        By Pond
                    </button>
                </div>
                <span className="anchor" id="allponds"></span>
                <h2>All Ponds</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>
                                    Balloon Fish
                                    <br />
                                    <br />
                                    Hot Air Balloon Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>
                                    Peanut Butter &amp; Jellyfish
                                    <br />
                                    <br />
                                    Grape PB&amp;J Fish
                                    <br />
                                    <br />
                                    Strawberry PB&amp;J Fish
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>Cat Fish</td>
                                <td><span style={{ color: "#285b9f" }}>VC (2)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={clownfish}
                                        alt="Clown Fish"
                                    />
                                    <span>Clown Fish</span>
                                </td>
                                <td>
                                    Clown Fish
                                    <br />
                                    <br />
                                    Sad Clown Fish
                                    <br />
                                    <br />
                                    Party Clown Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star Fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>
                                    Star Fish
                                    <br />
                                    <br />
                                    Five Star Fish
                                    <br />
                                    <br />
                                    Rock Star Fish
                                    <br />
                                    <br />
                                    Shining Star Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog Fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>
                                    Dog Fish
                                    <br />
                                    <br />
                                    Puppy Dog Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={amoreeel}
                                        alt="Amore Eel"
                                    />
                                    <span>Amore Eel</span>
                                </td>
                                <td>Amore Eel</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={nurseshark}
                                        alt="Nurse Shark"
                                    />
                                    <span>Nurse Shark</span>
                                </td>
                                <td>Nurse Shark</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King Crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>King Crab</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={seahorse}
                                        alt="Sea Horse"
                                    />
                                    <span>Sea Horse</span>
                                </td>
                                <td>
                                    Sea Horse
                                    <br />
                                    <br />
                                    Rocking Sea Horse
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={poolshark}
                                        alt="Pool Shark"
                                    />
                                    <span>Pool Shark</span>
                                </td>
                                <td>Pool Shark</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={cuttthroat}
                                        alt="Cuttthroat Trout"
                                    />
                                    <span>Cutthroat Trout</span>
                                </td>
                                <td>Cutthroat Trout</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano Fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>Piano Tuna</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="estate"></span>
                <h2>Estate</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>
                                    Peanut Butter & Jellyfish
                                    <br />
                                    <br />
                                    Grape PB&J Fish
                                    <br />
                                    <br />
                                    Crunchy PB&J Fish
                                    <br />
                                    <br />
                                    Strawberry PB&J Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>
                                    Tabby Cat Fish
                                    <br />
                                    <br />
                                    Tom Cat Fish
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star Fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>
                                    Shining Star Fish
                                    <br />
                                    <br />
                                    All Star Fish
                                </td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={holeymackerel}
                                        alt="Holey Mackerel"
                                    />
                                    <span>Holey Mackerel</span>
                                </td>
                                <td>Holey Mackerel</td>
                                <td><span style={{ color: "brown" }}>ER (9)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog Fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>
                                    Bull Dog Fish
                                    <br />
                                    <br />
                                    Hot Dog Fish
                                    <br />
                                    <br />
                                    Dalmatian Dog Fish
                                </td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={amoreeel}
                                        alt="Amore Eel"
                                    />
                                    <span>Amore Eel</span>
                                </td>
                                <td>
                                    Amore Eel
                                    <br />
                                    <br />
                                    Electric Amore Eel
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={seahorse}
                                        alt="Sea Horse"
                                    />
                                    <span>Sea Horse</span>
                                </td>
                                <td>
                                    Sea Horse
                                    <br />
                                    <br />
                                    Rocking Sea Horse
                                    <br />
                                    <br />
                                    Clydesdale Sea Horse
                                    <br />
                                    <br />
                                    Arabian Sea Horse
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="ttc"></span>
                <h2>Toontown Central</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>
                                    Hot Air Balloon Fish
                                    <br />
                                    <br />
                                    Red Balloon Fish
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>Peanut Butter &amp; Jellyfish</td>
                                <td><span style={{ color: "#285b9f" }}>VC (2)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={clownfish}
                                        alt="Clown Fish"
                                    />
                                    <span>Clown Fish</span>
                                </td>
                                <td>
                                    Clown Fish
                                    <br />
                                    <br />
                                    Sad Clown Fish
                                    <br />
                                    <br />
                                    Party Clown Fish
                                    <br />
                                    <br />
                                    Circus Clown Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star Fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>All Star Fish</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={holeymackerel}
                                        alt="Holey Mackerel"
                                    />
                                    <span>Holey Mackerel</span>
                                </td>
                                <td>Holey Mackerel</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="loopylane"></span>
                <h2>Loopy Lane (TTC)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>Red Balloon Fish</td>
                                <td><span style={{ color: "#285b9f" }}>VC (2)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="punchlineplace"></span>
                <h2>Punchline Place (TTC)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>Weather Balloon Fish</td>
                                <td><span style={{ color: "green" }}>C (5)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="sillystreet"></span>
                <h2>Silly Street (TTC)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>Water Balloon Fish</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="dock"></span>
                <h2>Donald's Dock</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star Fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>All Star Fish</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={holeymackerel}
                                        alt="Holey Mackerel"
                                    />
                                    <span>Holey Mackerel</span>
                                </td>
                                <td>Holey Mackerel</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog Fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>
                                    Dog Fish
                                    <br />
                                    <br />
                                    Bull Dog Fish
                                    <br />
                                    <br />
                                    Hot Dog Fish
                                    <br />
                                    <br />
                                    Dalmatian Dog Fish
                                    <br />
                                    <br />
                                    Puppy Dog Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (2)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King Crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>King Crab</td>
                                <td><span style={{ color: "#285b9f" }}>VC (3)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={poolshark}
                                        alt="Pool Shark"
                                    />
                                    <span>Pool Shark</span>
                                </td>
                                <td>
                                    Kiddie Pool Shark
                                    <br />
                                    <br />
                                    Swimming Pool Shark
                                    <br />
                                    <br />
                                    Olympic Pool Shark
                                </td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={cuttthroat}
                                        alt="Cuttthroat Trout"
                                    />
                                    <span>Cutthroat Trout</span>
                                </td>
                                <td>
                                    Cutthroat Trout
                                    <br />
                                    <br />
                                    Captain Cutthroat Trout
                                    <br />
                                    <br />
                                    Scurvy Cutthroat Trout
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="barnacle"></span>
                <h2>Barnacle Boulevard (Dock)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={cuttthroat}
                                        alt="Cuttthroat Trout"
                                    />
                                    <span>Cutthroat Trout</span>
                                </td>
                                <td>Captain Cutthroat Trout</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="seaweed"></span>
                <h2>Seaweed Street (Dock)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={cuttthroat}
                                        alt="Cuttthroat Trout"
                                    />
                                    <span>Cutthroat Trout</span>
                                </td>
                                <td>Scurvy Cutthroat Trout</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="lighthouse"></span>
                <h2>Lighthouse Lane (Dock)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King Crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>Old King Crab</td>
                                <td><span style={{ color: "#F62217" }}>VR (8)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="dg" id="fish"></span>
                <h2>Daisy Gardens</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>Water Balloon Fish</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>Crunchy PB&amp;J Fish</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>
                                    Cat Fish
                                    <br />
                                    <br />
                                    Siamese Cat Fish
                                    <br />
                                    <br />
                                    Tabby Cat Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                    <br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={devilray}
                                        alt="Devil Ray"
                                    />
                                    <span>Devil Ray</span>
                                </td>
                                <td>Devil Ray</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={amoreeel}
                                        alt="Amore Eel"
                                    />
                                    <span>Amore Eel</span>
                                </td>
                                <td>
                                    Amore Eel
                                    <br />
                                    <br />
                                    Electric Amore Eel
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon Fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>Harvest Moon Fish</td>
                                <td><span style={{ color: "green" }}>C (5)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={seahorse}
                                        alt="Sea Horse"
                                    />
                                    <span>Sea Horse</span>
                                </td>
                                <td>
                                    Sea Horse
                                    <br />
                                    <br />
                                    Rocking Sea Horse
                                    <br />
                                    <br />
                                    Clydesdale Sea Horse
                                    <br />
                                    <br />
                                    Arabian Sea Horse
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={poolshark}
                                        alt="Pool Shark"
                                    />
                                    <span>Pool Shark</span>
                                </td>
                                <td>
                                    Kiddie Pool Shark
                                    <br />
                                    <br />
                                    Swimming Pool Shark
                                    <br />
                                    <br />
                                    Olympic Pool Shark
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="elm"></span>
                <h2>Elm Street (DG)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>
                                    Siamese Cat Fish
                                    <br />
                                </td>
                                <td><span style={{ color: "brown" }}>ER (9)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="maple"></span>
                <h2>Maple Street (DG)</h2>
                <ul>
                    <span>N/A</span>
                </ul>
                <span className="anchor" id="oak"></span>
                <h2>Oak Street (DG)</h2>
                <ul>
                    <span>N/A</span>
                </ul>
                <span className="anchor" id="minnies"></span>
                <h2>Minnie's Melodyland</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={clownfish}
                                        alt="Clown Fish"
                                    />
                                    <span>Clown Fish</span>
                                </td>
                                <td>Circus Clown Fish</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star Fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>
                                    Five Star Fish
                                    <br />
                                    <br />
                                    Rock Star Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={nurseshark}
                                        alt="Nurse Shark"
                                    />
                                    <span>Nurse Shark</span>
                                </td>
                                <td>
                                    Nurse Shark
                                    <br />
                                    <br />
                                    Clara Nurse Shark
                                    <br />
                                    <br />
                                    Florence Nurse Shark
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span><br />
                                    <br /><span style={{ color: "brown" }}>ER (9)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano Fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>
                                    Piano Tuna
                                    <br />
                                    <br />
                                    Grand Piano Tuna
                                    <br />
                                    <br />
                                    Baby Grand Piano Tuna
                                    <br />
                                    <br />
                                    Upright Piano Tuna
                                    <br />
                                    <br />
                                    Player Piano Tuna
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                    <br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                    <br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="alto"></span>
                <h2>Alto Avenue (Melodyland)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano Fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>Grand Piano Tuna</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="baritone"></span>
                <h2>Baritone Boulevard (Melodyland)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={nurseshark}
                                        alt="Nurse Shark"
                                    />
                                    <span>Nurse Shark</span>
                                </td>
                                <td>Clara Nurse Shark</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="tenor"></span>
                <h2>Tenor Terrace (Melodyland)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={nurseshark}
                                        alt="Nurse Shark"
                                    />
                                    <span>Nurse Shark</span>
                                </td>
                                <td>Flourence Nurse Shark</td>
                                <td><span style={{ color: "#F62217" }}>VR (8)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano Fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>Baby Grand Piano Tuna</td>
                                <td><span style={{ color: "brown" }}>ER (9)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="brrrgh"></span>
                <h2>The Brrrgh</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>Weather Balloon Fish</td>
                                <td><span style={{ color: "darkorange" }}>R (6)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>
                                    Grape PB&amp;J Fish
                                    <br />
                                    <br />
                                    Concord Grape PB&amp;J Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={frozenfish}
                                        alt="Frozen Fish"
                                    />
                                    <span>Frozen Fish</span>
                                </td>
                                <td>Frozen Fish</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King Crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>Alaskan King Crab</td>
                                <td><span style={{ color: "darkorange" }}>R (7)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={bearacuda}
                                        alt="Bear Acuda"
                                    />
                                    <span>Bear Acuda</span>
                                </td>
                                <td>
                                    Brown Bear Acuda
                                    <br />
                                    <br />
                                    Black Bear Acuda
                                    <br />
                                    <br />
                                    Koala Bear Acuda
                                    <br />
                                    <br />
                                    Honey Bear Acuda
                                    <br />
                                    <br />
                                    Polar Bear Acuda
                                    <br />
                                    <br />
                                    Panda Bear Acuda
                                    <br />
                                    <br />
                                    Kodiac Bear Acuda
                                    <br />
                                    <br />
                                    Grizzly Bear Acuda
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (2)</span><br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span><br />
                                    <br /><span style={{ color: "green" }}>C (5)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "darkorange" }}>R (7)</span><br />
                                    <br /><span style={{ color: "brown" }}>ER (9)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="sleet"></span>
                <h2>Sleet Street (Brrrgh)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={bearacuda}
                                        alt="Bear Acuda"
                                    />
                                    <span>Bear Acuda</span>
                                </td>
                                <td>Kodiac Bear Acuda</td>
                                <td><span style={{ color: "#F62217" }}>VR (8)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="walrus"></span>
                <h2>Walrus Way (Brrrgh)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={bearacuda}
                                        alt="Bear Acuda"
                                    />
                                    <span>Bear Acuda</span>
                                </td>
                                <td>Grizzly Bear Acuda</td>
                                <td><span style={{ color: "maroon" }}>UR (10)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="polar"></span>
                <h2>Polar Place (Brrrgh)</h2>
                <ul>
                    <span>N/A</span>
                </ul>
                <span className="anchor" id="dreamland"></span>
                <h2>Donald's Dreamland</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>
                                    Strawberry PB&amp;J Fish
                                    <br />
                                    <br />
                                    Concord Grape PB&amp;J Fish
                                </td>
                                <td>
                                    <span style={{ color: "green" }}>C (5)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>Tom Cat Fish</td>
                                <td><span style={{ color: "#285b9f" }}>VC (2)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon Fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>
                                    Moon Fish
                                    <br />
                                    <br />
                                    Full Moon Fish
                                    <br />
                                    <br />
                                    New Moon Fish
                                    <br />
                                    <br />
                                    Harvest Moon Fish
                                </td>
                                <td>
                                    <span style={{ color: "#285b9f" }}>VC (1)</span><br />
                                    <br />
                                    <span style={{ color: "maroon" }}>UR (10)</span>
                                    <br />
                                    <br /><span style={{ color: "#285b9f" }}>VC (3)</span><br />
                                    <br /><span style={{ color: "green" }}>C (4)</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={devilray}
                                        alt="Devil Ray"
                                    />
                                    <span>Devil Ray</span>
                                </td>
                                <td>Devil Ray</td>
                                <td><span style={{ color: "brown" }}>ER (9)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="lullaby"></span>
                <h2>Lullaby Lane (Dreamland)</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Cat fish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>Alley Cat Fish</td>
                                <td><span style={{ color: "green" }}>C (4)</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon Fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>
                                    Crescent Moon Fish
                                    <br />
                                    <br />
                                    Half Moon Fish
                                </td>
                                <td>
                                    <span style={{ color: "darkorange" }}>R (6)</span><br />
                                    <br /><span style={{ color: "#F62217" }}>VR (8)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="pajama"></span>
                <h2>Pajama Place (Dreamland)</h2>
                <ul>
                    <span>N/A</span>
                </ul>
            </div>
        );
    }
}

export default Pond;
