import React from "react";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toonName: "",
            toonSpecies: "",
            email: "",
            message: "",
            response: "",
            sent: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        var template_params = {
            from_name: this.state.toonName,
            reply_to: this.state.email,
            to_name: process.env.REACT_APP_EMAIL_ADDRESS,
            message_html: this.state.message,
        };

        var service_id = process.env.REACT_APP_EMAIL_SERVICE_ID;
        var template_id = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
        window.emailjs
            .send(service_id, template_id, template_params)
            .then(
                alert(
                    "Email sent successfully! Thank you, Spike will get back to you soon!"
                ),
                this.props.history.push("/")
            );
    }

    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Contact Spike</h1>
                    <p>
                        Have a burning question about Toontown? Do you think
                        something on the site needs to be improved? Have a
                        suggestion? Please send them over by filling out the
                        contact form below! Spike will get back to you as soon
                        as possible!
                    </p>
                    <form>
                        <div>
                            <label htmlFor="Name">
                                Toon Name
                                <span style={{ color: "red" }}>
                                    {" "}
                                    *{" "}
                                </span>
                                <span title="NOT your real name, unless you feel so inclined">
                                    &#128712;
                                </span>
                            </label>
                            <input
                                id="Name"
                                name="Name"
                                type="text"
                                value={this.state.toonName}
                                placeholder="Please tell me your toon's name!"
                                onChange={(event) =>
                                    this.setState({
                                        toonName: event.target.value,
                                    })
                                }
                                style={{ marginBottom: "10px" }}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="Toon">Toon Species</label>
                            <select
                                id="Toon"
                                name="Toon"
                                value={this.state.toonSpecies}
                                onChange={(event) =>
                                    this.setState({
                                        toonSpecies: event.target.value,
                                    })
                                }
                                style={{ marginBottom: "10px" }}
                            >
                                <option value="">
                                    Select a toon species!
                                </option>
                                <option value="cat">Cat</option>
                                <option value="dog">Dog</option>
                                <option value="rabbit">Rabbit</option>
                                <option value="horse">Horse</option>
                                <option value="monkey">Monkey</option>
                                <option value="mouse">Mouse</option>
                                <option value="duck">Duck</option>
                                <option value="pig">Pig</option>
                                <option value="bear">Bear</option>
                                <option value="deer">Deer</option>
                                <option value="crocodile">
                                    Crocodile
                                </option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="Email">
                                Email Address
                                <span style={{ color: "red" }}>
                                    {" "}
                                    *{" "}
                                </span>
                                <span title="Your email address is NOT stored anywhere, I promise">
                                    &#128712;
                                </span>
                            </label>
                            <input
                                id="Email"
                                name="Email"
                                type="email"
                                value={this.state.email}
                                placeholder="Please tell me your email so I can respond!"
                                onChange={(event) =>
                                    this.setState({
                                        email: event.target.value,
                                    })
                                }
                                style={{ marginBottom: "10px" }}
                                required
                            />
                            {
                                this.state.email && !this.state.email.includes('@') ?
                                    <p style={{ fontSize: "12px", color: "red" }}>Please enter a valid email address</p> : null
                            }
                        </div>
                        <div>
                            <label htmlFor="Message">
                                Message
                                <span style={{ color: "red" }}>
                                    {" "}
                                    *{" "}
                                </span>
                            </label>
                            <textarea
                                id="Message"
                                name="Message"
                                placeholder="Type your message here!"
                                value={this.state.message}
                                rows="5"
                                onChange={(event) =>
                                    this.setState({
                                        message: event.target.value,
                                    })
                                }
                                style={{ marginBottom: "10px" }}
                                required
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            onClick={this.handleSubmit}
                            disabled={
                                !this.state.toonName
                                || !this.state.email
                                || !this.state.email.includes('@')
                                || !this.state.message
                            }
                        >
                            Send
                        </button>
                    </form>
                    <hr />
                    <h2>Looking for Toontown Game Support?</h2>
                    <p>
                        While this guide may be able to help answer your game
                        play questions, it cannot help you with technical
                        issues. If you are experiencing some game specific
                        problems, reach out to Toontown Rewritten Support.
                    </p>
                    <a href="mailto:support@toontownrewritten.com">
                        <button>TTR Support</button>
                    </a>
                </div>
            </div>
        );
    }
}

export default Contact;
