import React from "react";
import brrrgh from "../../assets/images/neighborhoods/brrrgh/brrrgh.PNG";
import brrrghHQ from "../../assets/images/neighborhoods/brrrgh/brrrgh_hq.PNG";
import brrrghClothing from "../../assets/images/neighborhoods/brrrgh/brrrgh_clothing.PNG";
import shirt95 from "../../assets/images/neighborhoods/brrrgh/Shirt_95.png";
import shirt150 from "../../assets/images/neighborhoods/brrrgh/Shirt_150.png";
import bshirt from "../../assets/images/neighborhoods/brrrgh/Bshirt.png";
import bshirt0 from "../../assets/images/neighborhoods/brrrgh/Bshirt0.png";
import bmale from "../../assets/images/neighborhoods/brrrgh/Bmale.png";
import bfemale from "../../assets/images/neighborhoods/brrrgh/Bfemale.png";
import animation_frame_tab from "../../assets/images/animation_frame_tab.PNG";

class TheBrrrgh extends React.Component {
    render() {
        return (
            <>
                <h2>The Brrrgh</h2>
                <p>
                    The Brrrgh is a snowy, winter-themed neighborhood that
                    is north-east of Toontown Central. The toon-up treasures
                    in the playground are snowflakes that provide toons with
                    a 12+ laff refill. This neighborhood is notorious for
                    its Lil Oldman task that requires defeating a lot of
                    high-level cogs and a large amount of cog buildings. The
                    Brrrgh is the fifth playground a toon will visit after
                    completing Minnie's Melodyland. It is also the final
                    playground toons will have to train for a new gag track.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src={brrrgh}
                    alt="The Brrrgh"
                />
                <p>
                    The neighborhood is connected to Donald's Dock, Minnie's
                    Melodyland, and Lawbot HQ. There are 3 main streets that
                    lead to those other neighborhoods or cog headquarters:
                </p>
                <ul>
                    <li>Walrus Way leads to Donald's Dock</li>
                    <li>Sleet Street leads to Minnie's Melodyland</li>
                    <li>Polar Place leads to Lawbot HQ</li>
                </ul>
                <p>
                    On the streets of The Brrrgh, you can find cogs higher
                    than level 6, reaching up to level 7. On Polar Place,
                    cogs level 8 and 9 can be seen walking the street.
                    Additionally, cog buildings reaching 5 floors can be
                    found.
                </p>
                <h3>Toontasks</h3>
                <p>
                    When starting gag training in The Brrrgh, a toon can
                    choose between the last two gag tracks a toon does not
                    have yet. To earn the chosen gag track and complete the
                    campaign in The Brrrgh, a toon must complete toontasks
                    to earn all 16 Animation frames. Progress on animation
                    frames can be viewed in the Shticker book animation
                    frame page{" "}
                    <span>
                        <img
                            className="book-tab"
                            src={animation_frame_tab}
                            alt="Animation frame book tab"
                        />
                    </span>
                    . Once all 16 animation frames are earned, a toon can
                    use their new gag track and move onto the next
                    neighborhood, Donald's Dreamland.
                </p>
                <p>
                    The toontasks for The Brrrgh, along with earning
                    Animation frames, are as follows:
                </p>
                <ul>
                    <li>Upgrade gag pouch to carry 50 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 120 total jellybeans
                    </li>
                    <li>
                        Increased toontask capacity to hold hour toontasks
                        at once
                    </li>
                    <li>Teleport access to The Brrrgh</li>
                    <li>+1 laff boost from Chicken Boy</li>
                    <li>+1 laff boost from Wynne Chill</li>
                    <li>+1 laff boost from Eddie the Yeti</li>
                    <li>+2 laff boost from Sweaty Pete</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>+3 laff boost from Lounge Lassard</li>
                </ul>
                <p>
                    There are a total of 10 laff boosts earnable through
                    toontasks in The Brrrgh. A toon can turn in their
                    completed toontasks at Toon HQ. The Toon HQ building in
                    The Brrrgh is an igloo! Toon HQ can be found in the
                    playground or on any street at the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src={brrrghHQ}
                    alt="The Brrrgh HQ"
                />
                <h3>Clothing Shop</h3>
                <p>
                    The The Brrrgh Clothing Shop is a store in The Brrrgh
                    that sells, well, clothes! It is located left of the The
                    Brrrgh Pet Shop. Toons may purchase clothing from this
                    shop with a clothing ticket or 200 jellybeans. A
                    clothing ticket is earned through a 'Just for Fun!'
                    toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src={brrrghClothing}
                    alt="The Brrrgh clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the The Brrrgh Clothing Shop. Some clothing is exclusive
                    to the gender of the toon, but most clothing is offered
                    to every toon. Clothing can typically be purchased in
                    any color of one's desire.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src={shirt95}
                                    alt="Yellow collared shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={shirt150}
                                    alt="Yellow shirt with bolt icon on the front"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={bshirt}
                                    alt="White shirt with flower stripe on bottom of torso and sleeves"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={bshirt0}
                                    alt="White blouse"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={bmale}
                                    alt="White shorts with red/yellow lightening stripes"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={bfemale}
                                    alt="Cargo skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default TheBrrrgh;
