import React from "react";
import { Link } from "react-router-dom";
import ddl from "../../assets/images/neighborhoods/dreamland/ddl.PNG";
import ddl_hq from "../../assets/images/neighborhoods/dreamland/ddl_hq.PNG";
import ddl_clothing from "../../assets/images/neighborhoods/dreamland/ddl_clothing.PNG";
import shirt_12 from "../../assets/images/neighborhoods/dreamland/Shirt_12.png";
import shirt_166 from "../../assets/images/neighborhoods/dreamland/Shirt_166.png";
import dlshirt from "../../assets/images/neighborhoods/dreamland/Dlshirt.png";
import dlshirt0 from "../../assets/images/neighborhoods/dreamland/Dlshirt0.png";
import dlmale from "../../assets/images/neighborhoods/dreamland/Dlmale.png";
import dlfemale from "../../assets/images/neighborhoods/dreamland/Dlfemale.png";

class DonaldsDreamland extends React.Component {
    render() {
        return (
            <>
                <h2>Donald's Dreamland</h2>
                <p>
                    Donald's Dreamland, often called DDL, is a night-time,
                    sleepy-themed neighborhood that is north of Minnie's
                    Melodyland. The toon-up treasures in the playground are
                    'zzz' icons that provide toons with a 12+ laff refill.
                    This neighborhood is known for having the most difficult
                    cogs on the streets. The Brrrgh is the sixth and last
                    playground a toon will visit after completing The
                    Brrrgh.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src={ddl}
                    alt="Donald's Dreamland"
                />
                <p>
                    The neighborhood is connected to Minnie's Melodyland,
                    and Cashbot HQ. There are 2 main streets that lead to
                    those other neighborhoods or cog headquarters:
                </p>
                <ul>
                    <li>Lullaby Lane leads to Minnie's Melodyland</li>
                    <li>Pajama Place leads to Cashbot HQ</li>
                </ul>
                <p>
                    On the streets of Donald's Dreamland, you will find the
                    most difficult cogs, starting at level 6 and reaching
                    level 9. Most cog buildings in the neighborhood are
                    either 4 or 5 floors.
                </p>
                <h3>Toontasks</h3>
                <p>
                    Upon reaching Donald's Dreamland, a toon has finished
                    training for new gag tracks. Yes, that is right, a toon
                    can only gain 6 out of 7 possible gag tracks. So, what
                    happens when you reach the final neighborhood? Great
                    question! Donald's Dreamland is dedicated to finish
                    upgrading a toon's laff boosts and gag pouch capacity.
                    Additionally, a toon will get the opportunity to build
                    their Cashbot suit disguise via toontasks.
                </p>
                <p>
                    The toontasks for Donald's Dreamland are split into 4
                    parts, meaning a preceeding part must be completed
                    before moving onto the next part. Donald's Dreamland's
                    toontasks are as follows:
                </p>
                <h4>Part 1</h4>
                <ul>
                    <li>Upgrade gag pouch to carry 60 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 150 total jellybeans
                    </li>
                    <li>Teleport access to Donald's Dreamland</li>
                    <li>+1 laff boost from Powers Erge</li>
                    <li>+2 laff boost from Lawful Linda</li>
                    <li>+3 laff boost from Rocco</li>
                    <li>+4 laff boost from Zari</li>
                    <li>Cashbot suit upper left leg part from Nat</li>
                    <li>
                        Cashbot suit lower left leg part from Teddy Blair
                    </li>
                    <li>Cashbot suit left foot part from William Teller</li>
                </ul>
                <h4>Part 2</h4>
                <ul>
                    <li>Upgrade gag pouch to carry 70 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 200 total jellybeans
                    </li>
                    <li>+1 laff boost from a random toontask</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>+3 laff boost from a random toontask</li>
                    <li>+4 laff boost from a random toontask</li>
                    <li>
                        Cashbot suit upper right leg part from Nina
                        Nitelight
                    </li>
                    <li>
                        Cashbot suit lower right leg part from Honey Moon
                    </li>
                    <li>Cashbot suit right foot part from Dreamy Daphne</li>
                    <li>Cashbot suit pelvis part from Smudgy Mascara</li>
                    <li>
                        Cashbot suit upper torso part from Sandy Sandman
                    </li>
                    <li>
                        Cashbot suit upper left arm part from a random
                        toontask
                    </li>
                    <li>
                        Cashbot suit lower left arm part from a random
                        toontask
                    </li>
                    <li>
                        Cashbot suit upper right arm part from a random
                        toontask
                    </li>
                    <li>
                        Cashbot suit lower right arm from a random toontask
                    </li>
                </ul>
                <h4>Part 3</h4>
                <ul>
                    <li>+1 laff boost from a random toontask</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>+3 laff boost from a random toontask</li>
                    <li>+4 laff boost from a random toontask</li>
                </ul>
                <h4>Part 4</h4>
                <ul>
                    <li>Upgrade gag pouch to carry 80 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 250 total jellybeans
                    </li>
                    <li>+1 laff boost from a random toontask</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>+3 laff boost from two random toontasks</li>
                </ul>
                <p>
                    There are a total of 36 laff boosts earnable through
                    toontasks in Donald's Dreamland. A toon can turn in
                    their completed toontasks at Toon HQ. The Toon HQ
                    building in Donald's Dreamland is a star observatory
                    wearing a night cap. Toon HQ can be found in the
                    playground or on any street at the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src={ddl_hq}
                    alt="Donald's Dreamland HQ"
                />
                <h3>Post Donald's Dreamland</h3>
                <p>
                    After completing the toontasks for Donald's Dreamland,
                    toons can build their Lawbot suit disguise and Bossbot
                    suit disguise via toontasks. Visit the{" "}
                    <Link to="/lawbots">Lawbot</Link> page or the{" "}
                    <Link to="/bossbots">Bossbot</Link> page to learn more
                    about those cogs and how to build those cog disguises.
                    Please note, the Lawbot suit disguise must be completely
                    built before obtaining toontasks to build a Bossbot suit
                    disguise.
                </p>
                <p>
                    Toons often wonder what to do after completing all of
                    the toontasks available in the game. After completing
                    the main campaign, toons can work on upgrading their cog
                    suit disguises and earning trophies in activites for
                    additional laff points, until they reach 137 laff.
                    Reaching 137 laff means a toon is maxed and the game is
                    fully completed.
                </p>
                <h3>Clothing Shop</h3>
                <p>
                    The Donald's Dreamland Clothing Shop is a store in
                    Donald's Dreamland that sells, well, clothes! It is
                    located left of the Pajama Place tunnel. Toons may
                    purchase clothing from this shop with a clothing ticket
                    or 200 jellybeans. A clothing ticket is earned through a
                    'Just for Fun!' toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src={ddl_clothing}
                    alt="Donald's Dreamland clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the Donald's Dreamland Clothing Shop. Some clothing is
                    exclusive to the gender of the toon, but most clothing
                    is offered to every toon. Clothing can typically be
                    purchased in any color of one's desire.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src={shirt_12}
                                    alt="Yellow wrinkled button up"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={shirt_166}
                                    alt="Collared button up with four front pockets"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={dlshirt}
                                    alt="White shirt with 3 hearts on the front"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={dlshirt0}
                                    alt="White shirt with drawn flower on the front"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={dlmale}
                                    alt="Demin shorts"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={dlfemale}
                                    alt="Demin skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default DonaldsDreamland;
