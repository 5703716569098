import React from "react";
import "../../scss/list.scss";
import laffodil from "../../assets/images/flowers/laff-o-dil.gif";
import dandyPansy from "../../assets/images/flowers/dandy-pansy.gif";
import whatInCarnation from "../../assets/images/flowers/what-in-carnation.gif";
import schoolDaisy from "../../assets/images/flowers/school-daisy.gif";
import lilyOfTheAlley from "../../assets/images/flowers/lily-of-the-alley.gif";
import daffyDill from "../../assets/images/flowers/daffy-dill.gif";
import chimPansy from "../../assets/images/flowers/chim-pansy.gif";
import instantCarnation from "../../assets/images/flowers/instant-carnation.gif";
import lazyDaisy from "../../assets/images/flowers/lazy-daisy.gif";
import lilyPad from "../../assets/images/flowers/lily-pad.gif";
import midsummerDaisy from "../../assets/images/flowers/midsummer-daisy.gif";
import potsenPansy from "../../assets/images/flowers/potsen-pansy.gif";
import hybridCarnation from "../../assets/images/flowers/hybrid-carnation.gif";
import summersLastRose from "../../assets/images/flowers/summers-last-rose.gif";
import tigerLily from "../../assets/images/flowers/tiger-lily.gif";
import giraffODil from "../../assets/images/flowers/giraff-o-dil.gif";
import marziPansy from "../../assets/images/flowers/marzi-pansy.gif";
import cornRose from "../../assets/images/flowers/corn-rose.gif";
import freshasaDaisy from "../../assets/images/flowers/freshasa-daisy.gif";
import liveredLily from "../../assets/images/flowers/livered-lily.gif";
import timeAndHalfODil from "../../assets/images/flowers/time-and-half-o-dil.gif";
import sideCarnation from "../../assets/images/flowers/side-carnation.gif";
import onelip from "../../assets/images/flowers/onelip.gif";
import whoopsieDaisy from "../../assets/images/flowers/whoopsie-daisy.gif";
import chiliLily from "../../assets/images/flowers/chili-lily.gif";
import tintedRose from "../../assets/images/flowers/tinted-rose.gif";
import smartyPansy from "../../assets/images/flowers/smarty-pansy.gif";
import twolip from "../../assets/images/flowers/twolip.gif";
import upsyDaisy from "../../assets/images/flowers/upsy-daisy.gif";
import sillyLily from "../../assets/images/flowers/silly-lily.gif";
import stinkingRose from "../../assets/images/flowers/stinking-rose.gif";
import modelCarnation from "../../assets/images/flowers/model-carnation.gif";
import carPetunia from "../../assets/images/flowers/car-petunia.gif";
import crazyDaisy from "../../assets/images/flowers/crazy-daisy.gif";
import indubitabLily from "../../assets/images/flowers/indubitab-lily.gif";
import istillaRose from "../../assets/images/flowers/istilla-rose.gif";
import threelip from "../../assets/images/flowers/threelip.gif";
import platoonia from "../../assets/images/flowers/platoonia.gif";
import hazyDaisy from "../../assets/images/flowers/hazy-daisy.gif";
import dillyLily from "../../assets/images/flowers/dilly-lily.gif";

class Species extends React.Component {
    render() {
        return (
            <div id="animate" className="list">
                <div className="list-navigation">
                    <a href="#daffodilNav">Daffodil Combos</a> |{" "}
                    <a href="#pansyNav">Pansy Combos</a> |{" "}
                    <a href="#daisyNav">Daisy Combos</a> |{" "}
                    <a href="#lilyNav">Lily Combos</a> |{" "}
                    <a href="#carnationNav">Carnation Combos</a>{" "}
                    | <a href="#tulipNav">Tulip Combos</a> |{" "}
                    <a href="#roseNav">Rose Combos</a> |{" "}
                    <a href="#petuniaNav">Petunia Combos</a>
                </div>
                <div className="filtering">
                    <button
                        name="num"
                        type="button"
                        onClick={() => {
                            this.props.action(true);
                        }}
                    >
                        Bean Cost
                    </button>
                    <button name="type" className="active">
                        Species/Color
                    </button>
                </div>
                <span className="anchor" id="daffodilNav"></span>
                <h2>Daffodil/Green Flower Combinations</h2>
                <ul>
                    {/*Green */}
                    <li
                        data-flower-type="daffodil"
                        data-bean-type="green"
                        data-num-beans="one"
                    >
                        Laff-o-dill
                    </li>
                    <img
                        src={laffodil}
                        alt="Laff-o-dil"
                    />
                    <div className="bean-colors" style={{ color: "green" }}>
                        green
                    </div>
                    <hr />
                    <li
                        data-flower-type="daffodil"
                        data-bean-type="green"
                        data-num-beans="two"
                    >
                        Daffy Dill
                    </li>
                    <img
                        src={daffyDill}
                        alt="Daffy dill"
                    />
                    <div className="bean-colors">
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daffodil"
                        data-bean-type="green"
                        data-num-beans="four"
                    >
                        Giraff-o-dil
                    </li>
                    <img
                        src={giraffODil}
                        alt="Giraff o dill"
                    />
                    <div className="bean-colors">
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>+{" "}
                        <span style={{ color: "gold" }}>yellow</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daffodil"
                        data-bean-type="green"
                        data-num-beans="five"
                    >
                        Time and half-o-dil
                    </li>
                    <img
                        src={timeAndHalfODil}
                        alt="Time and a Half o Dill"
                    />
                    <div className="bean-colors">
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span>
                    </div>
                </ul>
                <span className="anchor" id="pansyNav"></span>
                <h2>Pansy/Orange Flower Combinations</h2>
                <ul>
                    {/*Orange */}
                    <li
                        data-flower-type="pansy"
                        data-bean-type="orange"
                        data-num-beans="one"
                    >
                        Dandy Pansy
                    </li>
                    <img
                        src={dandyPansy}
                        alt="Dandy Pansy"
                    />
                    <div
                        className="bean-colors"
                        style={{ color: "darkorange" }}
                    >
                        orange
                    </div>
                    <hr />
                    <li
                        data-flower-type="pansy"
                        data-bean-type="orange"
                        data-num-beans="two"
                    >
                        Chim Pansy
                    </li>
                    <img
                        src={chimPansy}
                        alt="Chim Pansy"
                    />
                    <div className="bean-colors">
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="pansy"
                        data-bean-type="orange"
                        data-num-beans="three"
                    >
                        Potsen Pansy
                    </li>
                    <img src={potsenPansy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="pansy"
                        data-bean-type="orange"
                        data-num-beans="four"
                    >
                        Marzi Pansy
                    </li>
                    <img src={marziPansy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>+{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="pansy"
                        data-bean-type="orange"
                        data-num-beans="six"
                    >
                        Smarty Pansy
                    </li>
                    <img src={smartyPansy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span>+{" "}
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span>
                    </div>
                </ul>
                <span className="anchor" id="daisyNav"></span>
                <h2>Daisy/Yellow Flower Combinations</h2>
                <ul>
                    {/*Yellow */}
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="one"
                    >
                        School Daisy
                    </li>
                    <img src={schoolDaisy} alt="" />
                    <div className="bean-colors" style={{ color: "gold" }}>
                        yellow
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="two"
                    >
                        Lazy Daisy
                    </li>
                    <img src={lazyDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="three"
                    >
                        Midsummer Daisy
                    </li>
                    <img src={midsummerDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "green" }}>green</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="four"
                    >
                        Freshasa Daisy
                    </li>
                    <img src={freshasaDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                        + <span style={{ color: "darkorange" }}>orange</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="five"
                    >
                        Whoopsie Daisy
                    </li>
                    <img src={whoopsieDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="six"
                    >
                        Upsy Daisy
                    </li>
                    <img src={upsyDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="seven"
                    >
                        Crazy Daisy
                    </li>
                    <img src={crazyDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "red" }}>red</span>+{" "}
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "green" }}>green</span>+{" "}
                        <span style={{ color: "green" }}>green</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="daisy"
                        data-bean-type="yellow"
                        data-num-beans="eight"
                    >
                        Hazy Daisy
                    </li>
                    <img src={hazyDaisy} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>+{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "red" }}>red</span>+{" "}
                        <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>
                    </div>
                </ul>
                <span className="anchor" id="lilyNav"></span>
                <h2>Lily/Cyan Flower Combinations</h2>
                <ul>
                    {/*Cyan */}
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="one"
                    >
                        Lily-of-the-Alley
                    </li>
                    <img
                        src={lilyOfTheAlley}
                        alt=""
                    />
                    <div
                        className="bean-colors"
                        style={{ color: "darkturquoise" }}
                    >
                        cyan
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="two"
                    >
                        Lily Pad
                    </li>
                    <img src={lilyPad} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "green" }}>green</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="three"
                    >
                        Tiger Lily
                    </li>
                    <img src={tigerLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="four"
                    >
                        Livered Lily
                    </li>
                    <img src={liveredLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "darkorange" }}>orange</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "pink" }}>pink</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="five"
                    >
                        Chili Lily
                    </li>
                    <img src={chiliLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>+{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="six"
                    >
                        Silly Lily
                    </li>
                    <img src={sillyLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="seven"
                    >
                        Indubitab Lily
                    </li>
                    <img src={indubitabLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                        + <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "blue" }}>blue</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="lily"
                        data-bean-type="cyan"
                        data-num-beans="eight"
                    >
                        Dilly Lily
                    </li>
                    <img src={dillyLily} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>+{" "}
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>
                    </div>
                </ul>
                <span className="anchor" id="carnationNav"></span>
                <h2>Carnation/Pink Flower Combinations</h2>
                <ul>
                    {/*Pink */}
                    <li
                        data-flower-type="carnation"
                        data-bean-type="pink"
                        data-num-beans="one"
                    >
                        What-in Carnation
                    </li>
                    <img
                        src={whatInCarnation}
                        alt=""
                    />
                    <div className="bean-colors" style={{ color: "pink" }}>
                        pink
                    </div>
                    <hr />
                    <li
                        data-flower-type="carnation"
                        data-bean-type="pink"
                        data-num-beans="two"
                    >
                        Instant Carnation
                    </li>
                    <img
                        src={instantCarnation}
                        alt=""
                    />
                    <div className="bean-colors">
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="carnation"
                        data-bean-type="pink"
                        data-num-beans="three"
                    >
                        Hybrid Carnation
                    </li>
                    <img
                        src={hybridCarnation}
                        alt=""
                    />
                    <div className="bean-colors">
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="carnation"
                        data-bean-type="pink"
                        data-num-beans="five"
                    >
                        Side Carnation
                    </li>
                    <img src={sideCarnation} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "green" }}>green</span>+{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="carnation"
                        data-bean-type="pink"
                        data-num-beans="seven"
                    >
                        Model Carnation
                    </li>
                    <img src={modelCarnation} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "green" }}>green</span>+{" "}
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "green" }}>green</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>+{" "}
                        <span style={{ color: "green" }}>green</span>
                    </div>
                </ul>
                <span className="anchor" id="tulipNav"></span>
                <h2>Tulip/Violet Flower Combinations</h2>
                <ul>
                    {/*Purple */}
                    <li
                        data-flower-type="tulip"
                        data-bean-type="violet"
                        data-num-beans="five"
                    >
                        Onelip
                    </li>
                    <img src={onelip} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="tulip"
                        data-bean-type="violet"
                        data-num-beans="six"
                    >
                        Twolip
                    </li>
                    <img src={twolip} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>+{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="tulip"
                        data-bean-type="violet"
                        data-num-beans="eight"
                    >
                        Threelip
                    </li>
                    <img src={threelip} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "gold" }}>yellow</span>
                    </div>
                </ul>
                <span className="anchor" id="roseNav"></span>
                <h2>Rose/Red Flower Combinations</h2>
                <ul>
                    {/*Red */}
                    <li
                        data-flower-type="rose"
                        data-bean-type="red"
                        data-num-beans="three"
                    >
                        Summer's Last Rose
                    </li>
                    <img
                        src={summersLastRose}
                        alt=""
                    />
                    <div className="bean-colors">
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "red" }}>red</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="rose"
                        data-bean-type="red"
                        data-num-beans="four"
                    >
                        Corn Rose
                    </li>
                    <img src={cornRose} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "gold" }}>yellow</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="rose"
                        data-bean-type="red"
                        data-num-beans="six"
                    >
                        Tinted Rose
                    </li>
                    <img src={tintedRose} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="rose"
                        data-bean-type="red"
                        data-num-beans="seven"
                    >
                        Stinking Rose
                    </li>
                    <img src={stinkingRose} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                        +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="rose"
                        data-bean-type="red"
                        data-num-beans="eight"
                    >
                        Istilla Rose
                    </li>
                    <img src={istillaRose} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "blue" }}>blue</span>
                    </div>
                </ul>
                <span className="anchor" id="petuniaNav"></span>
                <h2>Petunia/Blue Flower Combinations</h2>
                <ul>
                    {/*Blue */}
                    <li
                        data-flower-type="petunia"
                        data-bean-type="blue"
                        data-num-beans="seven"
                    >
                        Car Petunia
                    </li>
                    <img src={carPetunia} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "blueviolet" }}>violet</span>{" "}
                        +{" "}
                        <span style={{ color: "darkturquoise" }}>cyan</span>{" "}
                        + <span style={{ color: "blue" }}>blue</span>+{" "}
                        <span style={{ color: "blue" }}>blue</span>
                    </div>
                    <hr />
                    <li
                        data-flower-type="petunia"
                        data-bean-type="blue"
                        data-num-beans="eight"
                    >
                        Platoonia
                    </li>
                    <img src={platoonia} alt="" />
                    <div className="bean-colors">
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span> +{" "}
                        <span style={{ color: "pink" }}>pink</span>+{" "}
                        <span style={{ color: "blue" }}>blue</span> +{" "}
                        <span style={{ color: "red" }}>red</span> +{" "}
                        <span style={{ color: "darkorange" }}>orange</span>+{" "}
                        <span style={{ color: "gold" }}>yellow</span> +{" "}
                        <span style={{ color: "gold" }}>yellow</span>
                    </div>
                </ul>
            </div>
        );
    }
}

export default Species;
