import React from "react";
import { Link } from "react-router-dom";
import Hamburger from "./Hamburger";
import HomeButton from "./HomeButton";
import Search from "./Search";

class Navigation extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 100) {
            document.querySelector('nav').classList.add('shrink');
        } else {
            document.querySelector('nav').classList.remove('shrink');
        }
    };

    render() {
        return (
            <nav>
                <div className="web-nav">
                    <div>
                        <HomeButton />
                        <div className="dropdown">
                            <Link className="dropbtn" to="/activities">
                                Activities&nbsp;
                                <i className="fa fa-caret-down"></i>
                            </Link>
                            <div className="dropdown-content">
                                <Link to="/gardening">Gardening</Link>
                                <Link to="/fishing">Fishing</Link>
                                <Link to="/racing">Racing</Link>
                                <Link to="/golfing">Golfing</Link>
                                <Link to="/doodles">Doodles</Link>
                            </div>
                        </div>
                        <div className="dropdown">
                            <Link className="dropbtn" to="/cogs">
                                Cogs&nbsp;
                                <i className="fa fa-caret-down"></i>
                            </Link>
                            <div className="dropdown-content">
                                <Link to="/sellbots">Sellbots</Link>
                                <Link to="/cashbots">Cashbots</Link>
                                <Link to="/lawbots">Lawbots</Link>
                                <Link to="/bossbots">Bossbots</Link>
                            </div>
                        </div>
                        <Link to="/neighborhoods">
                            Neighborhoods
                            {/* hidden icon to align header links on MacOS */}
                            <i
                                style={{ visibility: "hidden" }}
                                className="fa fa-caret-down"
                            ></i>
                        </Link>
                        <Link
                            style={{
                                marginLeft: -15 + "px",
                            }}
                            to="/toontips"
                        >
                            Toon Tips
                            {/* hidden icon to align header links on MacOS */}
                            <i
                                style={{
                                    visibility: "hidden",
                                }}
                                className="fa fa-caret-down"
                            ></i>
                        </Link>
                    </div>
                    <div className="nav-search">
                        <Search />
                    </div>
                </div>
                <div className="mobile-nav">
                    <HomeButton />
                    <Hamburger />
                </div>
            </nav>
        );
    }
}

export default Navigation;
