import React from "react";
import petshop from "../../assets/images/petshop.PNG";
import doodle_info from "../../assets/images/doodle_info.PNG";
import pet_details_tab from "../../assets/images/pet_details_tab.PNG";
import trick_training from "../../assets/images/trick_training.PNG";
import doodle_trick_calls from "../../assets/images/doodle_trick_calls.PNG";
import battle_rewards from "../../assets/images/battle_rewards.PNG";
import doodle_tab from "../../assets/images/doodle_tab.PNG";
import doodle_options from "../../assets/images/doodle_options.PNG";
import doodle_battle from "../../assets/images/doodle_battle.PNG";
import PanelNavigation from "../../components/PanelNavigation";

class Doodles extends React.Component {
    render() {
        const panelNavigationLinks = [
            { href: "#adopt", text: "Adopting a Doodle" },
            { href: "#train", text: "Trick Training" },
            { href: "#battle", text: "Calling a Doodle in Battle" },
            { href: "#traits", text: "Moods & Traits" },
        ];

        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Doodles</h1>
                    <p>
                        Have you ever found yourself wanting to have a
                        fluffy companion to greet you at the estate, and
                        assist you and your friends in cog battles? Well I
                        have the best news! Doodles are the pets of Toontown
                        and a toons best friend. Any toon can adopt a doodle
                        of their choice, as long as they have enough
                        jellybeans!
                    </p>
                    <PanelNavigation links={panelNavigationLinks} adSlot="8689384557" />
                    <span className="anchor" id="adopt"></span>
                    <h2>Adopting a Doodle</h2>
                    <p>
                        To adopt a doodle, a toon must go to any of the
                        Petshops located in each playground (excluding Chip
                        N' Dales Acorn Acres). A pet shop resembles a giant
                        fire hydrant with a massive fish tank on top,
                        displayed below.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src={petshop}
                        alt="Pet shop in Toontown Central"
                    />
                    <p>
                        Depending on which Pet Shop a toon adopts their
                        doodle from, they will get many randomized doodle
                        variations from colors and spots to tails, ears,
                        noses, and hair. There is no pattern to what doodles
                        will be available, it is just the luck of the draw!
                        These doodle options change everyday, so if you are
                        not satisified with your choices, come back another
                        day! However, if you see a doodle you love, do not
                        hesitate because it will be hard to find that same
                        doodle again. If a toon is not satisfied with the
                        purchased doodle, they can return their current
                        doodle without a refund.
                    </p>
                    <p>
                        Additionally, the playground in which a toon chooses
                        to purchase their doodle from determines the overall
                        quality of the doodle. In other words, a doodle
                        adopted in Toontown Central will not have as good of
                        traits as a doodle adopted in Donald's Dreamland.
                        Higher quality doodles are easier to train and
                        maintain, and they are more likely to listen. With
                        that being said, the higher quality doodles cost
                        significantly more jellybeans.
                    </p>
                    <img
                        className="badge"
                        style={{
                            marginTop: -15 + "px",
                            marginLeft: 5 + "px",
                            height: 175 + "px",
                        }}
                        src={doodle_info}
                        alt="Doodle info card"
                    />
                    <p>
                        After adopting a doodle, the doodle will live at the
                        toons estate. And if other toons on the account also
                        have doodles, they will all live in the estate
                        neighborhood and they will all be available for
                        interaction for any toon that is logged in. When a
                        toon arrives at their estate, the doodles tags will
                        appear on the screen, just like other online toons
                        name tags appear. Upon clicking the doodle's name
                        tag, a small box will show up, similar to when
                        another toons name is clicked. This box shows a
                        doodles information such as its tricks tab, and the
                        ability to call, feed, and pet the doodle. On top of
                        that, a doodle's current mood is displayed. A toons
                        doodle also appears on the friends list so they can
                        check on their doodle at any given time.
                    </p>
                    <span className="anchor" id="train"></span>
                    <h2>Trick Training</h2>
                    <p>
                        Doodles can be trained to do tricks for toons, which
                        can heal a set amount of laff depending on the
                        trick. These tricks can be done simply at a toons
                        estate. They can also be called into a cog battle as
                        an SOS to perform a trick to heal all the toons
                        partaking in the battle. Training a trick boosts how
                        much laff a trick can restore and reduces the chance
                        of the doodle getting confused. Trick training can
                        be tracked on the doodles info card, by clicking on
                        the Pet Details arrow{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={pet_details_tab}
                                alt="Pet details button"
                            />
                        </span>
                        .
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 225 + "px" }}
                        src={trick_training}
                        alt="Trick training doodle card"
                    />
                    <p>
                        Every toon starts out with the trick phrase "Jump!".
                        The rest of the trick phrases can be purchased in
                        Clarabelle's Cattlelog. To use a trick phrase, open
                        up SpeedChat and go to PETS then TRICKS. There you
                        can find a list of the trick phrases a toon has
                        available. Shout the trick around your doodle and
                        see if they perform! The more they perform, the
                        better they will be and the trick experience goes
                        up. Doodles can become tired after doing tricks,
                        signaled by the 'zzz' cloud icon, and will need to
                        take a break. To speed up the resting process and to
                        make the doodle feel happy, a toon can feed him/her
                        with jellybeans and praise him/her by saying "Good
                        boy!" or "Good girl!".
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 250 + "px" }}
                        src={doodle_trick_calls}
                        alt="SpeedChat doodle tricks"
                    />
                    <p>
                        Please note that doodle trick training is a lengthy
                        task and requires a lot of patience. Each trick bar
                        has to be filled up as many times as the original
                        laff restore to be completely maxed. For example,
                        the Jump trick bar has to be filled 5 times and the
                        Speak trick bar has to be filled 11 times.
                    </p>
                    <p>
                        The following table lists all possible trick phrases
                        and the laff restore ranges:
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Doodle Trick</th>
                                <th>Laff Points Restored</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Jump</td>
                                <td>5 - 10</td>
                            </tr>
                            <tr>
                                <td>Beg</td>
                                <td>6 - 12</td>
                            </tr>
                            <tr>
                                <td>Play Dead</td>
                                <td>7 - 14</td>
                            </tr>
                            <tr>
                                <td>Rollover</td>
                                <td>8 - 16</td>
                            </tr>
                            <tr>
                                <td>Backflip</td>
                                <td>9 - 18</td>
                            </tr>
                            <tr>
                                <td>Dance</td>
                                <td>10 - 20</td>
                            </tr>
                            <tr>
                                <td>Speak</td>
                                <td>11 - 22</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <span className="anchor" id="battle"></span>
                    <h2>Calling a Doodle in Battle</h2>
                    <p>
                        Just like SOS toons, doodles can also be summoned in
                        a battle to help heal participating toons. A toon
                        can call their doodle by selecting the suitcase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={battle_rewards}
                                alt="Battle suitcase to access rewards"
                            />
                        </span>{" "}
                        button to the right of the gag choice menu then
                        select the doodle{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={doodle_tab}
                                alt="Doodle call button"
                            />
                        </span>{" "}
                        tab. Now a toon can choose which trick they want
                        their doodle to perform in battle.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 250 + "px" }}
                        src={doodle_options}
                        alt="Doodle trick options"
                    />
                    <p>
                        When a toon calls their doodle, the doodle tries to
                        perform the selected trick. If it fails, the doodle
                        is sad. If it succeeds, all toons will be rewarded
                        laff points, similar to how Toon-up works. Training
                        your doodle increases the likelihood of a doodle
                        successfully performing a trick in battle. The
                        amount of laff given is determined by the trick
                        performed. After the doodles attempt, they will
                        return back to their owner's estate.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 250 + "px" }}
                        src={doodle_battle}
                        alt="SOS doodle in cog battle"
                    />
                    <span className="anchor" id="traits"></span>
                    <h2>Moods &amp; Traits</h2>
                    <p>
                        All doodles can experience different types of moods,
                        which affects behavior. Different traits a doodle
                        may come with can affect how often certain moods
                        occur. For example, if a doodle is often tried, then
                        that doodle will experience tiredness frequently.
                        Each mood has its own unique symbol that appears
                        above a doodles head.
                    </p>
                    <ul>
                        <li>
                            <strong>Affectionate</strong> - The doodle is feeling
                            loving towards their owner and may want more
                            attention. When the doodle is affectionate, a
                            pink heart will be above their head.
                        </li>
                        <li>
                            <strong>Confused</strong> - The doodle is confused. This
                            might be the result of a doodle not
                            understanding a trick command. When any doodle
                            is confused, a question mark will be above their
                            head.
                        </li>
                        <li>
                            <strong>Hungry</strong> - The doodle is hungry and wants
                            jellybeans! When the doodle is hungry, a chicken
                            leg will be above their head. The symbol will
                            disappear when a toon feeds the doodle.
                        </li>
                        <li>
                            <strong>Bored</strong> - The doodle is bored and wants
                            more interaction. When the doodle is bored, an
                            ellipsis (...) will appear. The symbol will
                            disappear when scratching the doodle and
                            practicing tricks.
                        </li>
                        <li>
                            <strong>Excited</strong> - The doodle is thrilled to see
                            their owner. When the doodle is excited, an
                            exclamation mark will appear.
                        </li>
                        <li>
                            <strong>Sad</strong> - The doodle feels neglected and sad.
                            This can happen if a toon is saying mean things
                            to them. When the doodle is sad, a deep-colored
                            blue crying face will be above their head. The
                            symbol will disappear with positive interaction.
                        </li>
                        <li>
                            <strong>Sleepy</strong> - The doodle is tired. This also
                            means the doodle does not want to perform tricks
                            right now. When the doodle is tired, a purple
                            thought bubble with 'ZZZ' will be above their
                            head. The symbol will disappear with
                            interaction.
                        </li>
                        <li>
                            <strong>Lonely</strong> - The doodle is lonely and needs
                            their owner's attention. When the doodle is
                            lonely, an symbol of doodles huddled together
                            will be above their head. The symbol will
                            disappear with interaction.
                        </li>
                        <li>
                            <strong>Angry</strong> - The doodle is angry with their
                            owner. This can result from neglection and
                            negative interaction. When the doodle is angry,
                            a grey cloud with lightning bolts will be above
                            their head. The symbol will disappear with
                            positive interaction.
                        </li>
                    </ul>
                    <br />
                    <br />
                    <PanelNavigation links={panelNavigationLinks} adSlot="1733984228" />
                </div>
            </div>
        );
    }
}

export default Doodles;
