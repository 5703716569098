import React from "react";
import "../../scss/list.scss";
import balloonfish from "../../assets/images/fish/balloonfish.png";
import starfish from "../../assets/images/fish/starfish.png";
import jellyfish from "../../assets/images/fish/jellyfish.png";
import catfish from "../../assets/images/fish/catfish.png";
import clownfish from "../../assets/images/fish/clownfish.png";
import dog from "../../assets/images/fish/dog.png";
import amoreeel from "../../assets/images/fish/amoreeel.png";
import nurseshark from "../../assets/images/fish/nurseshark.png";
import kingcrab from "../../assets/images/fish/kingcrab.png";
import seahorse from "../../assets/images/fish/seahorse.png";
import poolshark from "../../assets/images/fish/poolshark.png";
import cuttthroat from "../../assets/images/fish/cuttthroat.png";
import pianofish from "../../assets/images/fish/pianofish.png";
import holeymackerel from "../../assets/images/fish/holeymackerel.png";
import devilray from "../../assets/images/fish/devilray.png";
import moonfish from "../../assets/images/fish/moonfish.png";
import bearacuda from "../../assets/images/fish/bearacuda.png";
import frozenfish from "../../assets/images/fish/frozenfish.png";

class Rod extends React.Component {
    render() {
        let tableHeader = (
            <tr>
                <th>Fish Type</th>
                <th>Fish Species</th>
            </tr>
        );

        return (
            <div id="animate" className="list">
                <div className="list-navigation">
                    <a href="#twig">Twig Rod</a> |{" "}
                    <a href="#bamboo">Bamboo Rod</a> |{" "}
                    <a href="#hardwood">Hardwood Rod</a> |{" "}
                    <a href="#steel">Steel Rod</a> |{" "}
                    <a href="#gold">Gold Rod</a>
                </div>
                <div className="filtering">
                    <button
                        name="species"
                        type="button"
                        onClick={() => {
                            this.props.action(true, false);
                        }}
                    >
                        By Fish
                    </button>
                    <button name="rod" className="active">
                        By Rod
                    </button>
                    <button
                        name="pond"
                        type="button"
                        onClick={() => {
                            this.props.action(false, true);
                        }}
                    >
                        By Pond
                    </button>
                </div>
                <span className="anchor" id="twig"></span>
                <h2>Twig Rod</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={balloonfish}
                                        alt="Balloon fish"
                                    />
                                    <span>Balloon Fish</span>
                                </td>
                                <td>
                                    Balloon Fish
                                    <br />
                                    <br />
                                    Weather Balloon Fish
                                    <br />
                                    <br />
                                    Red Balloon Fish
                                    <br />
                                    <br />
                                    Hot Air Balloon Fish
                                    <br />
                                    <br />
                                    Water Balloon Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={jellyfish}
                                        alt="Jellyfish"
                                    />
                                    <span>Jellyfish</span>
                                </td>
                                <td>
                                    Crunchy PB&amp;J Fish
                                    <br />
                                    <br />
                                    Concord Grape PB&amp;J Fish
                                    <br />
                                    <br />
                                    Strawberry PB&amp;J Fish
                                    <br />
                                    <br />
                                    Grape PB&amp;J Fish
                                    <br />
                                    <br />
                                    Peanut Butter &amp; Jellyfish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Catfish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>
                                    Cat Fish
                                    <br />
                                    <br />
                                    Tabby Cat Fish
                                    <br />
                                    <br />
                                    Siamese Cat Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={clownfish}
                                        alt="Clown fish"
                                    />
                                    <span>Clown Fish</span>
                                </td>
                                <td>
                                    Clown Fish
                                    <br />
                                    <br />
                                    Sad Clown Fish
                                    <br />
                                    <br />
                                    Party Clown Fish
                                    <br />
                                    <br />
                                    Circus Clown Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>
                                    Star Fish
                                    <br />
                                    <br />
                                    Five Star Fish
                                    <br />
                                    <br />
                                    All Star Fish
                                    <br />
                                    <br />
                                    Shining Star Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>
                                    Puppy Dog Fish
                                    <br />
                                    <br />
                                    Dalmatian Dog Fish
                                    <br />
                                    <br />
                                    Hot Dog Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={devilray}
                                        alt="Devil Ray"
                                    />
                                    <span>Devil Ray</span>
                                </td>
                                <td>Devil Ray</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={amoreeel}
                                        alt="Amore eel"
                                    />
                                    <span>Amore Eel</span>
                                </td>
                                <td>
                                    Amore Eel
                                    <br />
                                    <br />
                                    Electric Amore Eel
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={nurseshark}
                                        alt="Nurse shark"
                                    />
                                    <span>Nurse Shark</span>
                                </td>
                                <td>
                                    Nurse Shark
                                    <br />
                                    <br />
                                    Clara Nurse Shark
                                    <br />
                                    <br />
                                    Florence Nurse Shark
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>
                                    King Crab
                                    <br />
                                    <br />
                                    Old King Crab
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>
                                    Moon Fish
                                    <br />
                                    <br />
                                    Crescent Moon Fish
                                    <br />
                                    <br />
                                    New Moon Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={cuttthroat}
                                        alt="Cuttthroat trout"
                                    />
                                    <span>Cutthroat Trout</span>
                                </td>
                                <td>
                                    Cutthroat Trout
                                    <br />
                                    <br />
                                    Captain Cutthroat Trout
                                    <br />
                                    <br />
                                    Scurvy Cutthroat Trout
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="bamboo"></span>
                <h2>Bamboo Rod</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={catfish}
                                        alt="Catfish"
                                    />
                                    <span>Cat Fish</span>
                                </td>
                                <td>
                                    Alley Cat Fish
                                    <br />
                                    <br />
                                    Tom Cat Fish
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={frozenfish}
                                        alt="Frozen fish"
                                    />
                                    <span>Frozen Fish</span>
                                </td>
                                <td>Frozen Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={starfish}
                                        alt="Star fish"
                                    />
                                    <span>Star Fish</span>
                                </td>
                                <td>Rock Star Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={holeymackerel}
                                        alt="Holey Mackerel"
                                    />
                                    <span>Holey Mackerel</span>
                                </td>
                                <td>Holey Mackerel</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>Dog Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={kingcrab}
                                        alt="King crab"
                                    />
                                    <span>King Crab</span>
                                </td>
                                <td>Alaskan King Crab</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>Half Moon Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={poolshark}
                                        alt="Pool shark"
                                    />
                                    <span>Pool Shark</span>
                                </td>
                                <td>
                                    Kiddie Pool Shark
                                    <br />
                                    <br />
                                    Olympic Pool Shark
                                    <br />
                                    <br />
                                    Swimming Pool Shark
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="hardwood"></span>
                <h2>Hardwood Rod</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon shark"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>Harvest Moon Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={seahorse}
                                        alt="Sea horse"
                                    />
                                    <span>Sea Horse</span>
                                </td>
                                <td>Sea Horse</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={poolshark}
                                        alt="Pool shark"
                                    />
                                    <span>Pool Shark</span>
                                </td>
                                <td>Pool Shark</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={bearacuda}
                                        alt="Bear acuda"
                                    />
                                    <span>Bear Acuda</span>
                                </td>
                                <td>
                                    Black Bear Acuda
                                    <br />
                                    <br />
                                    Brown Bear Acuda
                                    <br />
                                    <br />
                                    Honey Bear Acuda
                                    <br />
                                    <br />
                                    Koala Bear Acuda
                                    <br />
                                    <br />
                                    Polar Bear Acuda
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>
                                    Baby Grand Piano Tuna
                                    <br />
                                    <br />
                                    Player Piano Tuna
                                    <br />
                                    <br />
                                    Upright Piano Tuna
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="steel"></span>
                <h2>Steel Rod</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={moonfish}
                                        alt="Moon fish"
                                    />
                                    <span>Moon Fish</span>
                                </td>
                                <td>Full Moon Fish</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={seahorse}
                                        alt="Sea horse"
                                    />
                                    <span>Sea Horse</span>
                                </td>
                                <td>
                                    Arabian Sea Horse
                                    <br />
                                    <br />
                                    Clydesdale Sea Horse
                                    <br />
                                    <br />
                                    Rocking Sea Horse
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={bearacuda}
                                        alt="Bear acuda"
                                    />
                                    <span>Bear Acuda</span>
                                </td>
                                <td>
                                    Grizzly Bear Acuda
                                    <br />
                                    <br />
                                    Kodiac Bear Acuda
                                    <br />
                                    <br />
                                    Panda Bear Acuda
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={pianofish}
                                        alt="Piano fish"
                                    />
                                    <span>Piano Tuna</span>
                                </td>
                                <td>
                                    Piano Tuna
                                    <br />
                                    <br />
                                    Grand Piano Tuna
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
                <span className="anchor" id="gold"></span>
                <h2>Gold Rod</h2>
                <ul>
                    <table>
                        <thead>{tableHeader}</thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={dog}
                                        alt="Dog fish"
                                    />
                                    <span>Dog Fish</span>
                                </td>
                                <td>Bull Dog Fish</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
            </div>
        );
    }
}

export default Rod;
