import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const items = [
    {
        date: "September 30th, 2024",
        list: <>
            <ul>
                <li>
                    Added new page detailing the <Link to="/undernewmanagement">Under New Management</Link> update.
                </li>
                <li>
                    Updated cog facility information to reflect the Under New Management update.
                </li>
                <li>
                    Added information about <Link to="/statuseffects">status effects</Link> seen in cog battles.
                </li>
            </ul>
        </>
    },
    {
        date: "January 27th, 2024",
        list: <>
            <ul>
                <li>
                    Update <Link to="/taskforce">Sellbot Task Force</Link> page with info about Kaboomberg
                    and four-star Field Offices
                </li>
                <li>Add more detail to <Link to="/activities#cartoonival">Cartoonival</Link> information.</li>
            </ul>
        </>
    },
    {
        date: "July 8th, 2023",
        list: <>
            <ul>
                <li>Added a brand-new page about <Link to="/gags">gags</Link></li>
                <li>Other site improvements, such as load time in <Link to="/neighborhoods">Neighborhoods</Link></li>
            </ul>
        </>
    },
    {
        date: "February 4, 2023",
        list: <>
            <ul>
                <li>Updated fishing guide to reflect new fishing balance updates</li>
            </ul>
        </>
    },
    {
        date: "January 7, 2023",
        list: <>
            <ul>
                <li>Added new walk-through video for the C.E.O. battle</li>
                <li>
                    Added short tutorial videos for various activites such as
                    fishing, gardening, and party planning
                </li>
                <li>Added informational links for Task Force rewards</li>
            </ul>
        </>
    },
    {
        date: "October 2, 2022",
        list: <>
            <ul>
                <li>
                    Updated Bossbot and organic gag tips
                </li>
                <li>
                    Refined C.E.O. battle description
                </li>
                <li>
                    Fixed site navigation to exclude hash (#) marks
                </li>
                <li>
                    Other site styling improvements
                </li>
            </ul>
        </>
    },
    {
        date: "August 21, 2022",
        list: <>
            <ul>
                <li>
                    Updated <Link to="/taskforce">Sellbot Task Force</Link>{" "}
                    page to include Smasher set information
                </li>
            </ul>
        </>
    },
    {
        date: "May 14, 2022",
        list: <>
            <ul>
                <li>
                    Updated golf course stock option rewards and C.E.O.
                    play-through on the{" "}
                    <Link to="/bossbots">Bossbots</Link> page to reflect
                    the 3.2.0 update
                </li>
                <li>
                    Added information about Reinforced Plating on
                    Version 2.0 cogs
                </li>
                <li>
                    Bossbot HQ Survival tips updated in{" "}
                    <Link to="/toontips">Toon Tips</Link> to reflect
                    the 3.2.0 update
                </li>
                <li>
                    Surviving Sellbot Field Offices tips updated in{" "}
                    <Link to="/toontips">Toon Tips</Link>
                </li>
                <li>
                    Added walkthrough video for{" "}
                    <Link to="/fieldoffices">
                        Sellbot Field Offices
                    </Link>{" "}
                    in game
                </li>
                <li>
                    Updated damage information for remote control
                    rewards
                </li>
                <li>
                    Added a section for a quick way to locate{" "}
                    <Link to="/fieldoffices">
                        Sellbot Field Offices
                    </Link>
                </li>
                <li>
                    Changed the image for the reward for maxing golfing
                </li>
            </ul>
        </>
    },
    {
        date: "December 29, 2021",
        list: <>
            <ul>
                <li>
                    Information and tips for the all-new{" "}
                    <Link to="/fieldoffices">
                        Sellbot Field Offices
                    </Link>
                </li>
                <li>
                    Information and insights into the{" "}
                    <Link to="/taskforce">Sellbot Task Force</Link>
                </li>
                <li>
                    Surviving Sellbot Field Offices tips added to{" "}
                    <Link to="/toontips">Toon Tips</Link>
                </li>
                <li>
                    Minor updates to charts, screenshots, and how-tos to
                    account for game mechanics and UI changes
                </li>
                <li>
                    Added cog health point information for cog levels 13
                    through 20 to the <Link to="/cogs">Cogs</Link> page
                </li>
            </ul>
        </>
    },
    {
        date: "November 21, 2021",
        list: <>
            <ul>
                <li>
                    Added information and screenshots for activity
                    rewards, such as the maxed fishing outfit
                </li>
                <li>
                    Added information about Version 2.0 cogs on the{" "}
                    <Link to="/bossbots">Bossbots</Link> page
                </li>
            </ul>
        </>
    },
    {
        date: "August 1, 2021",
        list: <>
            <ul>
                <li>
                    Trophy information updated for{" "}
                    <Link to="/golfing">golfing</Link> and{" "}
                    <Link to="/fishing">fishing</Link> to reflect change
                    in requirements
                </li>
            </ul>
        </>
    },
    {
        date: "May 8, 2021",
        list: <>
            <ul>
                <li>
                    All-new <Link to="/search">search</Link> bar and
                    page to make website navigation easier
                </li>
            </ul>
        </>
    },
    {
        date: "May 2, 2021",
        list: <>
            <ul>
                <li>
                    Organic gag tips improved and added-to in{" "}
                    <Link to="/toontips">Toon Tips</Link>
                </li>
            </ul>
        </>
    },
    {
        date: "January 18, 2021",
        list: <>
            <ul>
                <li>
                    Brand-new{" "}
                    <Link to="/neighborhoods">Neighborhoods</Link> page
                    to learn about Toontown neighborhoods and what they
                    have to offer
                </li>
                <li>
                    More <Link to="/toontips">Toon Tips</Link> for
                    special holiday toons
                </li>
                <li>
                    Cog health point data added to the{" "}
                    <Link to="/cogs">Cogs</Link> page
                </li>
                <li>
                    Cartoonival information added to the{" "}
                    <Link to="/activities">Activities</Link> page
                </li>
                <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                    added
                </li>
                <li>Website style redesigned</li>
            </ul>
        </>
    },
    {
        date: "November 28, 2020",
        list: <>
            <ul>
                <li>
                    Fixed anchor tags so landing spot is not under the
                    header
                </li>
                <li>
                    Color coordinated fish rarity legend added to the{" "}
                    <Link to="/fishing">fishing</Link> guide
                </li>
            </ul>
        </>
    },
    {
        date: "July 15, 2020",
        list: <>
            <ul>
                <li>
                    New website theme: Cog Mode. Switch themes in the
                    website footer
                </li>
            </ul>
        </>
    },
    {
        date: "May 9, 2020",
        list: <>
            <ul>
                <li>
                    General cog information, such as cog buildings and
                    invasions, added to an all-new{" "}
                    <Link to="/cogs">Cogs</Link> page
                </li>
                <li>
                    General activity information, such as trolley games
                    and the silly meter, added to an all-new{" "}
                    <Link to="/activities">Activities</Link> page
                </li>
            </ul>
        </>
    },
    {
        date: "January 4, 2020",
        list: <>
            <ul>
                <li>
                    C.E.O. walkthrough videos added on the{" "}
                    <Link to="/bossbots">Bossbots</Link> page
                </li>
            </ul>
        </>
    }
];

function Items({ currentItems }) {
    return (
        <>
            {
                currentItems && currentItems.map((item) => (
                    <div>
                        <h3>{item.date}</h3>
                        {item.list}
                        <hr />
                    </div>
                ))
            }
        </>
    );
}

function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel={<i className="fa fa-caret-right"></i>}
                previousLabel={<i className="fa fa-caret-left"></i>}
                activeClassName={'item active'}
                disabledClassNAme={'disabled'}
                containerClassName={'pagination'}
                nextClassName={"item next"}
                previousClassName={"item previous"}
            />
        </>
    );
}

class WhatsNew extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>What's New!</h1>
                    <PaginatedItems itemsPerPage={8} />
                </div>
            </div>
        );
    }
}

export default WhatsNew;
