import React from "react";
import { Link } from "react-router-dom";
import chipndales from "../../assets/images/neighborhoods/chipndales/chipndales.PNG";

class ChipNDalesAcornAcres extends React.Component {
    render() {
        return (
            <>
                <h2>Chip N' Dales Acorn Acres</h2>
                <p>
                    Chip N' Dales Acorn Acres, or commonly called Acorn
                    Acres for short, is a campy, picnic-themed neighborhood
                    located southeast of Toontown Central. This neighborhood
                    is known for its access to{" "}
                    <Link to="/golfing">Chip N' Dales Golf</Link> and to the
                    Cartoonival Grounds, previously known as ToonFest, when
                    it is open. The playground has a geyser where toons can
                    be launched into the air and has several picnic tables
                    for aesthetic. The toon-up treasures in the playground
                    are acorns and provide a 3+ laff refill.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src={chipndales}
                    alt="Chip N' Dales Acorn Acres"
                />
                <p>
                    Chip N' Dales Acorn Acres is a neighborhood that does
                    not have any streets connecting to other neighborhoods.
                    There are no cogs to fight and no toontasks to complete
                    here. This playground is purely recreational. However,
                    toons automatically receive teleport access upon
                    visiting the playground the first time.
                </p>
            </>
        );
    }
}

export default ChipNDalesAcornAcres;
