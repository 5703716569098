import React from "react";
import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Privacy Policy</h1>
                    <h2>Your privacy is important</h2>
                    <p>
                        It is Spike's Rewritten Guide's policy to respect your
                        privacy regarding any information we may collect while
                        operating our website. This Privacy Policy applies to{" "}
                        <a href="https://spikesrewrittenguide.com">
                            {" "}
                            spikesrewrittenguide.com
                        </a>{" "}
                        (hereinafter, "us", "we", or
                        "spikesrewrittenguide.com"). We respect your privacy and
                        are committed to protecting personally identifiable
                        information you may provide us through the Website. We
                        have adopted this privacy policy ("Privacy Policy") to
                        explain what information may be collected on our
                        Website, how we use this information, and under what
                        circumstances we may disclose the information to third
                        parties. This Privacy Policy applies only to information
                        we collect through the Website and does not apply to our
                        collection of information from other sources.
                    </p>
                    <h2>Definitions</h2>
                    <p>For the purposes of this Privacy Policy</p>
                    <ul>
                        <li>
                            <strong>"Cookies"</strong>{" "}are small files placed on
                            Your computer, mobile device, or any other device by a
                            website, containing details of Your browsing history on
                            that website among its many uses.
                        </li>
                        <li>
                            <strong>"Device"</strong>{" "}means any device that can access
                            this website such as a computer, a cell phone, or a digital
                            tablet.
                        </li>
                        <li>
                            <strong>"Personal Data"</strong>{" "}is any information that
                            realtes to an identified or identifiable individual.
                        </li>
                        <li>
                            <strong>"Website"</strong>{" "}refers to Spike's Rewritten
                            Guide, accessible from spikesrewrittenguide.com.
                        </li>
                        <li>
                            <strong>"You"</strong>{" "}means the individual accessing
                            or using this website, or other legal entity on behalf of
                            such individual is accessing or using the Website,
                            as applicable.
                        </li>
                    </ul>
                    <h2>Contents</h2>
                    <p>
                        Click below to jump to any section of this privacy
                        policy:
                    </p>
                    <ol type="1">
                        <li>
                            <a href="#websitevisitors">
                                Website Visitors
                            </a>
                        </li>
                        <li>
                            <a href="#personaldata">
                                Personal Data
                            </a>
                        </li>
                        <li>
                            <a href="#useofpersonaldata">
                                Use of Personal Data
                            </a>
                        </li>
                        <li>
                            <a href="#googleanalytics">
                                Google Analytics
                            </a>
                        </li>
                        <li>
                            <a href="#Advertising">Website Ads</a>
                        </li>
                        <li>
                            <a href="#Security">Security</a>
                        </li>
                        <li>
                            <a href="#ExternalLinks">
                                Links To External Sites
                            </a>
                        </li>
                        <li>
                            <a href="#Changes">
                                Privacy Policy Changes
                            </a>
                        </li>
                    </ol>
                    <span className="anchor" id="websitevisitors"></span>
                    <h2>1. Website Visitors</h2>
                    <p>
                        Like most website operators, Spike's Rewritten Guide
                        collects non-personally-identifying information of the
                        sort that web browsers and servers typically make
                        available, such as the browser type, language
                        preference, referring site, and the date and time of
                        each visitor request. Spike's Rewritten Guide's purpose
                        in collecting non-personally identifying information is
                        to better understand how Spike's Rewritten Guide's
                        visitors use its website. See{" "}
                        <a href="#googleanalytics">Google Analytics</a>.
                    </p>
                    <span className="anchor" id="personaldata"></span>
                    <h2>2. Personal Data</h2>
                    <p>
                        While using Spike's Rewritten Guide, we may ask You to
                        provide certain personally identifiable information that
                        can be used to contact or identify You. Personally identifiable
                        information may include:
                    </p>
                    <ul>
                        <li>Email address</li>
                    </ul>
                    <span className="anchor" id="useofpersonaldata"></span>
                    <h2>3. Use of Personal Data</h2>
                    <p>
                        Spike's Rewritten Guide may use Personal Data to contact you
                        by email and manage any requests. When You submit a request
                        through our contact form, Your email address is used for
                        the sole purpose of responding the said request. This information
                        is not retained or stored.
                    </p>
                    <span className="anchor" id="googleanalytics"></span>
                    <h2>4. Google Analytics</h2>
                    <p>
                        Google Analytics is a web analytics service offered by
                        Google that tracks and reports website traffic. Google
                        uses the data collected to track and monitor the use of
                        this website. This data is shared with other Google services.
                        Google may use the collected data to contextualize and
                        personalize the ads of its own advertising network.
                    </p>
                    <p>
                        You can opt-out of having your activity made available to
                        Google Analytics by installing the Google Analytics opt-out
                        browser add-on.
                    </p>
                    <p>
                        For more information, please visit Google's Privacy Policy:{" "}
                        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                            https://policies.google.com/privacy
                        </a>
                    </p>
                    <span className="anchor" id="Advertising"></span>
                    <h2>5. Website Ads</h2>
                    <p>
                        Spike's Rewritten Guide shows non-intrusive ads,
                        using Google AdSense. Third party vendors, including
                        Google, use cookies to serve ads. Google's use of
                        advertising cookies enables it and its partners to
                        serve ads based on Your visits to this website and/or
                        other sites on the internet.
                    </p>
                    <p>
                        You may opt-out of the use of the advertising cookie
                        for interest-based advertising by visiting Ad Settings
                        or{" "}
                        <a href="https://youradchoices.com/" target="_blank" rel="noopener noreferrer">
                            aboutads.info
                        </a>.
                    </p>
                    <span className="anchor" id="Security"></span>
                    <h2>6. Security</h2>
                    <p>
                        The security of your information is important, but
                        remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While we strive to use commercially acceptable
                        means to protect your Personal Data, we cannot
                        guarantee its absolute security.
                    </p>
                    <span className="anchor" id="ExternalLinks"></span>
                    <h2>7. Links To External Sites</h2>
                    <p>
                        Our website may contain links to external sites that are
                        not operated by us. If you click on a third party link,
                        you will be directed to that third party's site. We
                        strongly advise you to review the Privacy Policy and
                        terms of service of every site you visit.
                    </p>
                    <p>
                        We have no control over, and assume no responsibility
                        for the content, privacy policies or practices of any
                        third party sites, products or services.
                    </p>
                    <span className="anchor" id="Changes"></span>
                    <h2>8. Privacy Policy Changes</h2>
                    <p>
                        Although most changes are likely to be minor, Spike's
                        Rewritten Guide may change its Privacy Policy from time
                        to time, and in Spike's Rewritten Guide's sole
                        discretion. Spike's Rewritten Guide encourages visitors
                        to frequently check this page for any changes to its
                        Privacy Policy. Your continued use of this site after
                        any change in this Privacy Policy will constitute your
                        acceptance of such change.
                    </p>
                    <p>
                        If you have any questions about our Privacy Policy,
                        please <Link to="/contact">contact us</Link>.
                    </p>
                    <br />
                    <p>
                        <em>Effective Date: June 8, 2023</em>
                    </p>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
