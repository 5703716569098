import React from 'react';
import Ad from "./Ad";

const PanelNavigation = props => {
    const { links } = props;
    const { adSlot } = props;

    return (
        <>
            <div className="panel-nav">
                {links.map((link, index) => (
                    <a key={index} href={link.href}>{link.text}</a>
                ))}
            </div>
            <br />
            <Ad dataAdSlot={adSlot} />
        </>
    );
}

export default PanelNavigation;
