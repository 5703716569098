import React from "react";
import { Link } from "react-router-dom";
import PanelNavigation from "../../components/PanelNavigation";
import factory_foreman from "../../assets/images/cogs/sellbots/factory_foreman.png";
import mint_auditor from "../../assets/images/cogs/cashbots/mint_auditor.png";
import office_clerk from "../../assets/images/cogs/lawbots/office_clerk.png";
import club_president from "../../assets/images/cogs/bossbots/club_president.png";
import UNM_logo from "../../assets/images/UNM_logo.png";
import Slick_Shirt from "../../assets/images/Slick_Shirt.png";
import Slick_Skirt from "../../assets/images/Slick_Skirt.png";
import Slick_Slacks from "../../assets/images/Slick_Slacks.png";
import Foreman_Hat from "../../assets/images/Foreman_Hat.png";
import Auditor_Shoes from "../../assets/images/Auditor_Shoes.png";
import Clerk_Glasses from "../../assets/images/Clerk_Glasses.png";
import Club_President_Golf_Backpack from "../../assets/images/Club_President_Golf_Backpack.png";
import Statuseffect_healovertime from "../../assets/images/Statuseffect_healovertime.png";
import Statuseffect_bearmarket from "../../assets/images/Statuseffect_bearmarket.png";
import Statuseffect_bullmarket from "../../assets/images/Statuseffect_bullmarket.png";
import Statuseffect_offlimits from "../../assets/images/Statuseffect_offlimits.png";

class UnderNewManagement extends React.Component {
    render() {
        const panelNavigationLinks = [
            { href: "#overhauls", text: "Facility Overhauls" },
            { href: "#supervisors", text: "New Supervisor Cogs" },
            { href: "#v2disguises", text: "Version 2.0 Cog Disguises" },
            { href: "#cosmetics", text: "New Cosmetics" },
            { href: "#patchnotes", text: "Patch Notes" }
        ];

        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Under New Management</h1>
                    <p>
                        "Under New Management" is a Toontown Rewritten major update, rolled out
                        on May 24, 2024, during ToonFest: The Great Fanfair. It was first announced
                        on May 26, 2023, at ToonFest: Birthday Bash, with a breaking newscast from
                        COG-TV revealing a management shakeup at Cogs, Incorporated. This update
                        introduces four new sinister Supervisor Cogs, revamped cog facilities,
                        Version 2.0 cog disguises, and fresh cosmetics.
                    </p>
                    <p>
                        Throughout February 2024, the Cogs conducted various observational
                        analyses to monitor and evaluate the performance of the new Supervisor Cogs.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src={UNM_logo}
                        alt="Under New Management logo"
                    />
                    <PanelNavigation links={panelNavigationLinks} adSlot="8689384557" />
                    <span className="anchor" id="overhauls"></span>
                    <h2>Facility Overhauls</h2>
                    <p>
                        The Cog facilities have undergone a complete rework to make them more challenging
                        and engaging for Toons. The Cog facilities now come in two flavors:
                        easy-mode and hard-mode. Easy-mode keeps things chill with the "laid-back"
                        style of the original facilities, perfect for a relaxed run. But if you're looking
                        for a real challenge, hard-mode is where it's at! High risk means high reward!
                    </p>
                    <mark>Video walk-throughs coming soon!</mark>
                    <span className="anchor" id="supervisors"></span>
                    <h2>New Supervisor Cogs</h2>
                    <p>
                        The Board of Directors at Cogs, Inc. has decided to shake things up by introducing
                        new management. These new Supervisor's are not to be taken lightly, they are ready
                        to exploit any opening in toons' strategies with their{" "}
                        <Link to="/statuseffects">status effects</Link>{" "}
                        during battles. Meet the new bosses:
                    </p>
                    <h3>Factory Foreman</h3>
                    <img
                        style={{ width: 150 + "px" }}
                        src={factory_foreman}
                        alt="Factory Foreman"
                    />
                    <p>
                        The Factory Foreman is the Supervisor Cog of the <Link to="/sellbots">Sellbots</Link>,
                        known for his special moves that come with status effects.
                        He is stationed in the Center Silo Control Room of the Sellbot
                        Factory at Sellbot Headquarters. When you defeat him and the
                        accompanying Cogs, you earn skill points for your gags and
                        some merits. If you have not completed your Sellbot Cog
                        Disguise yet, you will receive disguise parts instead of Merits.
                    </p>
                    <p>
                        Easy-mode in a Sellbot Factory is known as the <strong>Scrap Factory</strong>.
                        Hard-mode is called the <strong>Steel Factory</strong>.
                    </p>
                    <p>
                        The Factory Foreman kicks off the battle by casting Defense Up on the cogs.
                        This nifty boost cranks up their defense by 25% in both the Scrap Factory
                        and the Steel Factory. According to damage calculations,
                        the Factory Foreman packs 250 health in the Scrap Factory and a
                        hefty 750 health in the Steel Factory.
                    </p>
                    <h4>
                        Overtime Phase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={Statuseffect_healovertime}
                                alt="Repair Over Time"
                            />
                        </span>
                    </h4>
                    <p>
                        Occurs when The Factory Foreman's health is low.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Status Effect
                                </th>
                                <th>
                                    Scrap Factory
                                </th>
                                <th>
                                    Steel Factory
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Damage Up
                                </td>
                                <td>
                                    Damage Up boosts The Factory Foreman's damage by 25%.
                                </td>
                                <td>
                                    Damage Up boosts The Factory Foreman's damage by 50%.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Time Down
                                </td>
                                <td>
                                    Time Down reduces the battle timer by 25 seconds.
                                </td>
                                <td>
                                    Time Down reduces the battle timer by 32 seconds.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Attacks &amp; Special Moves</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Attack
                                </th>
                                <th>
                                    Scrap Factory
                                </th>
                                <th>
                                    Steel Factory
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Tremor
                                </td>
                                <td>
                                    6 (+2 during Overtime) damage
                                </td>
                                <td>
                                    15 (+8 during Overtime) damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Hot Air
                                </td>
                                <td>
                                    7 (+2 during Overtime) damage
                                </td>
                                <td>
                                    19 (+10 during Overtime) damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Red Tape
                                </td>
                                <td>
                                    4 (+1 during Overtime) damage
                                </td>
                                <td>
                                    15 (+8 during Overtime) damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Finger Wag
                                </td>
                                <td>
                                    9 (+3 during Overtime) damage
                                </td>
                                <td>
                                    22 (+11 during Overtime) damage
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Special Move
                                </th>
                                <th>
                                    Scrap Factory
                                </th>
                                <th>
                                    Steel Factory
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Burnout
                                </td>
                                <td>
                                    --
                                </td>
                                <td>
                                    Damage Over Time deals 8 damage per round.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Field Promotion
                                </td>
                                <td>
                                    Fired Up boosts a Cog's damage by 25% and reduces their
                                    defense by 50% for one round.
                                </td>
                                <td>
                                    Fired Up boosts a Cog's damage by 50% and reduces their
                                    defense by 50% for one round.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Mint Auditor</h3>
                    <img
                        style={{ width: 150 + "px" }}
                        src={mint_auditor}
                        alt="Mint Auditor"
                    />
                    <p>
                        The Mint Auditor is the Supervisor Cog of the <Link to="/cashbots">Cashbots</Link>,
                        known for his special moves that come with status effects.
                        He is located near the Mint Vault in the Cashbot Mints at
                        Cashbot Headquarters. When you defeat him and the other cogs,
                        you earn skill points for your gags and some cogbucks.
                    </p>
                    <p>
                        Easy-mode in a Cashbot Mint is known as the <strong>Coin Mint</strong>.
                        Hard-mode is called the <strong>Bullion Mint</strong>. For all of you
                        seasoned toons out there, there is no more Dollar Mint!
                    </p>
                    <p>
                        The Mint Auditor starts the showdown by applying Overpaid and
                        Tenure on the cogs. Overpaid gives the cogs a decent health boost of
                        +150 in the Coin Mint and +200 in the Bullion Mint. Tenure, on the other hand,
                        makes the cogs immune to pink slips and remote controls. A bit inconvenient, right?
                        Based on damage calculations, the Mint Auditor is estimated to have 750
                        health in the Coin Mint and a solid 1150 health in the Bullion Mint.
                    </p>
                    <h4>
                        Bear Market Phase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={Statuseffect_bearmarket}
                                alt="Bear Market"
                            />
                        </span>
                    </h4>
                    <p>
                        In both the Coin Mint and the Bullion Mint, the Bear Market Phase causes gags to
                        cost double due to "gag tax" and toons have increased defense by 50% due to Defense Up.
                    </p>
                    <h4>
                        Bull Market Phase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={Statuseffect_bullmarket}
                                alt="Bull Market"
                            />
                        </span>
                    </h4>
                    <p>
                        The Bear Market Phase and Bull Market Phase alternate every time a cog is defeated.
                        Gag tax is removed in the Bull Market Phase.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Coin Mint
                                </th>
                                <th>
                                    Bullion Mint
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Damage Up boosts damage for cogs and toons by 25%.
                                </td>
                                <td>
                                    Damage Up boosts damage for cogs and toons by 50%.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Attacks &amp; Special Moves</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Attack
                                </th>
                                <th>
                                    Coin Mint
                                </th>
                                <th>
                                    Bullion Mint
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Audit
                                </td>
                                <td>
                                    6 damage / 13 damage during Bull Market
                                </td>
                                <td>
                                    11 damage / 23 damage during Bull Market
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Synergy
                                </td>
                                <td>
                                    7 damage / 14 damage during Bull Market
                                </td>
                                <td>
                                    12 damage / 24 damage during Bull Market
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Market Crash
                                </td>
                                <td>
                                    8 damage / 16 damage during Bull Market
                                </td>
                                <td>
                                    13 damage / 26 damage during Bull Market
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Power Tie
                                </td>
                                <td>
                                    5 damage / 11 damage during Bull Market
                                </td>
                                <td>
                                    9 damage / 19 damage during Bull Market
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Special Move
                                </th>
                                <th>
                                    Coin Mint
                                </th>
                                <th>
                                    Bullion Mint
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Promoted
                                </td>
                                <td>
                                    Cogs are promoted by one level.
                                </td>
                                <td>
                                    Cogs are promoted by two levels.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Investment
                                </td>
                                <td>
                                    Cogs are healed +25 health.
                                    Attentive reduces the Lure effect to a single turn for 2 rounds.
                                </td>
                                <td>
                                    Cogs are healed +50 health.
                                    Attentive reduces the Lure effect to a single turn for 3 rounds.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Office Clerk</h3>
                    <img
                        style={{ width: 150 + "px" }}
                        src={office_clerk}
                        alt="Office Clerk"
                    />
                    <p>
                        The Office Clerk is the Supervisor Cog of the <Link to="/lawbots">Lawbots</Link>,{" "}
                        equipped with special moves that come with status effects. She resides in the
                        Law Library at the end of the Lawbot DA Offices in Lawbot Headquarters.
                        Once you defeat her and the other Cogs, Toons receive skill points for their
                        gags and a certain number of Jury Notices.
                    </p>
                    <p>
                        Easy-mode in a DA Office is known as the <strong>Junior Wing</strong>.
                        Hard-mode is called the <strong>Senior Wing</strong>. For all of you
                        seasoned toons out there, no more A, B, C, and D offices!
                    </p>
                    <p>
                        The battle kicks off with The Office Clerk fuming about Toons barging
                        into her facility. Soon enough, more Cogs show up to join the fray.
                        Once you take down the Cogs, she calls in Virtual Skelecogs that
                        explode after she bites the dust. Damage calculations suggest
                        The Office Clerk has 1100 health in the Junior Wing and a
                        hefty 1500 health in the Senior Wing.
                    </p>
                    <h4>Attacks &amp; Special Moves</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Attack
                                </th>
                                <th>
                                    Junior Wing
                                </th>
                                <th>
                                    Senior Wing
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Rododex
                                </td>
                                <td>
                                    8 damage
                                </td>
                                <td>
                                    24 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Restraining Order
                                </td>
                                <td>
                                    11 damage
                                </td>
                                <td>
                                    29 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Guilt Trip
                                </td>
                                <td>
                                    10 damage
                                </td>
                                <td>
                                    27 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Legalese
                                </td>
                                <td>
                                    13 damage
                                </td>
                                <td>
                                    36 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shh!
                                </td>
                                <td>
                                    25% of Sound gag damage dealt to The Office Clerk
                                </td>
                                <td>
                                    50% of Sound gag damage dealt to The Office Clerk
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Special Move
                                </th>
                                <th>
                                    Junior Wing
                                </th>
                                <th>
                                    Senior Wing
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Witness Protection
                                </td>
                                <td>
                                    Gag Immunity allows Cogs to be immune to Sound, Throw, Squirt, and Drop gags.
                                    Attentive reduces the Lure effect to a single turn for 1 round.
                                    Cogs are healed +30 health.
                                </td>
                                <td>
                                    Gag Immunity allows Cogs to be immune to Sound, Throw, Squirt, and Drop gags.
                                    Attentive reduces the Lure effect to a single turn for 2 rounds.
                                    Cogs are healed +50 health.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Book Smart
                                </td>
                                <td>
                                    Damage Up boosts damage by 15% for 2 rounds.
                                    Attentive reduces the Lure effect to a single turn for 1 round.
                                    Cogs are healed +30 health.
                                </td>
                                <td>
                                    Damage Up boosts damage by 30% for 2 rounds.
                                    Attentive reduces the Lure effect to a single turn for 2 rounds.
                                    Cogs are healed +50 health.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Objection
                                </td>
                                <td colSpan={2}>
                                    Toons must use Sound, Throw, Squirt,
                                    or Drop gags in the next round or receive additional damage.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Objection Sustained
                                </td>
                                <td>
                                    15 damage and elimination of a gag track for
                                    3 rounds due to gag ordinance.
                                </td>
                                <td>
                                    25 damage and elimination of a gag track for
                                    4 rounds due to gag ordinance.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Club President</h3>
                    <img

                        style={{ width: 150 + "px" }}
                        src={club_president}
                        alt="Club President"
                    />
                    <p>
                        The Club President is the supervisor of the <Link to="/bossbots">Bossbots</Link>,
                        armed with special moves that come with status effects.
                        You will find her near the Final Hole at the end of the
                        Bossbot Golf Courses in Bossbot HQ.
                        When you and your Toon crew take her and the other Cogs down,
                        you'll earn skill points for your gags and a bunch of Stock Options.
                    </p>
                    <p>
                        Easy-mode in a Golf Course is known as <strong>The First Fairway</strong>.
                        Hard-mode is called <strong>The Final Fringe</strong>. No more Middle Six,
                        another shake-up!
                    </p>
                    <p>
                        The battle starts with The Club President swooping in and ordering
                        her minions to handle the pesky Toons. She then hits the toons with
                        Delayed Damage for good measure. Damage calculations suggest The Club President
                        has 1200 health in the First Fairway and a solid 1750 health in the Final Fringe.
                    </p>
                    <h4>
                        Presidential Protection Phase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={Statuseffect_offlimits}
                                alt="Off Limits"
                            />
                        </span>
                    </h4>
                    <p>
                        In this phase, which occurs in both The First Fairway and The Final Fringe,
                        happens when The Club President loses all of her health. The Club President
                        cannot be targeted anymore because she is off-limits.
                        Toons must end the battle by defeating the remaining cogs.
                    </p>
                    <h4>Attacks &amp; Special Moves</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Attack
                                </th>
                                <th>
                                    The First Fairway
                                </th>
                                <th>
                                    The Final Fringe
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Tee Off
                                </td>
                                <td>
                                    18 damage
                                </td>
                                <td>
                                    23 damage
                                </td></tr>
                            <tr>
                                <td>
                                    Evil Eye
                                </td>
                                <td>
                                    19 damage
                                </td>
                                <td>
                                    25 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Head Shrink
                                </td>
                                <td>
                                    21 damage
                                </td>
                                <td>
                                    28 damage
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Quake
                                </td>
                                <td>
                                    16 damage
                                </td>
                                <td>
                                    21 damage
                                </td>
                            </tr>
                            <tr>
                                <td>Bogey
                                </td>
                                <td colSpan={2}>
                                    The amount of damage from Delayed Damage for failing to best The Club President
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bunker Shot
                                </td>
                                <td>
                                    12 damage after one Cog is defeated
                                    14 damage after two Cogs are defeated
                                    17 damage after three Cogs are defeated
                                </td>
                                <td>
                                    17 damage after one Cog is defeated
                                    19 damage after two Cogs are defeated
                                    22 damage after three Cogs are defeated
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>
                                    Special Move
                                </th>
                                <th>
                                    The First Fairway
                                </th>
                                <th>
                                    The Final Fringe
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Setting Par
                                </td>
                                <td>
                                    Delayed Damage deals 50 damage after 5 rounds.Lowered to 40 damage after one Cog is defeated.Lowered to 30 damage after two Cogs are defeated.Lowered to 20 damage after three Cogs are defeated.
                                </td>
                                <td>
                                    Delayed Damage deals 90 damage after 5 rounds.Lowered to 70 damage after one Cog is defeated.Lowered to 50 damage after two Cogs are defeatedLowered to 30 damage after three Cogs are defeated.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Sandtrapped
                                </td>
                                <td>
                                    Elimination of Toons' last chosen gag track for 1 round due to Gag Ordinance.
                                </td>
                                <td>
                                    Elimination of Toons' last chosen gag track for 2 rounds due to Gag Ordinance.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Overwork
                                </td>
                                <td>
                                    Attentive reduces the Lure effect to a single turn for 1 round.
                                </td>
                                <td>
                                    Attentive reduces the Lure effect to a single turn for 2 rounds.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Penalty Stroke
                                </td>
                                <td colSpan={2}>
                                    Decreased defense for The Club President by 20% after one Cog is defeated.Further decreased to 40% after two Cogs are defeated.Further decreased to 60% after three Cogs are defeated.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Driving Range
                                </td>
                                <td>
                                    Damage Up boosts a Cog's damage by 25% for 3 rounds during Presidential Protection.
                                </td>
                                <td>
                                    Damage Up boosts a Cog's damage by 50% for 3 rounds during Presidential Protection.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Swing Oil
                                </td>
                                <td>
                                    Cogs are healed +25 health during Presidential Protection.
                                </td>
                                <td>
                                    Cogs are healed +50 health during Presidential Protection.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="v2disguises"></span>
                    <h2>Version 2.0 Cog Disguises</h2>
                    <p>
                        Ready to climb the corporate ladder even higher?
                        Say hello to Version 2.0 Cog Disguises! Once you hit level 50 on any suit,
                        you can upgrade your disguise and start the climb all over again.
                        This time, you can switch your disguise to any of the predecessor cogs.
                        But beware, the requirements for Merits, Cogbucks, Jury Notices, and Stock Options
                        are steeper than ever!
                    </p>
                    <span className="anchor" id="cosmetics"></span>
                    <h2>New Cosmetics</h2>
                    <p>
                        Who says you can't look good while taking down Cogs?
                        With the new cosmetics, you can strut your stuff in style. Defeat the Supervisor
                        Cogs to snag awesome items like the Foreman Hat, Auditor Shoes, Clerk Glasses, and
                        Club President Golf Backpack. Thanks to a nifty pity system, you're guaranteed to
                        get these goodies after a certain number of wins in Cog facility runs. Plus, if
                        you tackle hard-mode runs or start a Version 2.0 Cog Disguise, your chances of
                        scoring these items go up even more. And for the ultimate fashion statement,
                        max out any Version 2.0 Cog Disguise to unlock the Slick Outfit!
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Clothing</th>
                                <th>Name</th>
                                <th>How to Obtain</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img
                                        src={Foreman_Hat}
                                        alt="Foreman Hat"
                                    />
                                </td>
                                <td>
                                    Foreman Hat
                                </td>
                                <td>
                                    Defeat the Factory Foreman.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={Auditor_Shoes}
                                        alt="Auditor Shoes"
                                    />
                                </td>
                                <td>
                                    Auditor Shoes
                                </td>
                                <td>
                                    Defeat the Mint Auditor.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={Clerk_Glasses}
                                        alt="Clerk Glasses"
                                    />
                                </td>
                                <td>
                                    Clerk Glasses
                                </td>
                                <td>
                                    Defeat the Office Clerk.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={Club_President_Golf_Backpack}
                                        alt="Club President Golf Backpack"
                                    />
                                </td>
                                <td>
                                    Club President Golf Backpack
                                </td>
                                <td>
                                    Defeat the Club President.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src={Slick_Shirt}
                                        alt="Slick Shirt"
                                    />
                                    <br />
                                    <img
                                        src={Slick_Skirt}
                                        alt="Slick Skirt"
                                    />
                                    <br />
                                    <img
                                        src={Slick_Slacks}
                                        alt="Slick Slacks"
                                    />
                                </td>
                                <td>
                                    Slick Outfit
                                </td>
                                <td>
                                    Max out any Version 2.0 Cog Disguise.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="patchnotes"></span>
                    <h2>Patch Notes</h2>
                    <p>
                        For a detailed breakdown of all the changes, check out the{" "}
                        <a
                            href="https://cdn.toontownrewritten.com/community/notices/Toontown_Rewritten_Rebalancing_v4.0.0_Patch_Notes.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >{" "}
                            4.0.0 Patch Notes
                        </a>.
                    </p>
                    <br />
                    <br />
                    <PanelNavigation links={panelNavigationLinks} adSlot="1733984228" />
                </div>
            </div >
        );
    }
}

export default UnderNewManagement;
