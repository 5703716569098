import React from "react";
import { Link } from "react-router-dom";
import hideout_entrance from "../../../assets/images/cogs/sellbots/taskforce/hideout_entrance.PNG";
import hideout1 from "../../../assets/images/cogs/sellbots/taskforce/hideout1.PNG";
import resistance_rank_tab from "../../../assets/images/resistance_rank_tab.PNG";
import resistance_rank_info from "../../../assets/images/resistance_rank_info.PNG";
import resistance_rank_complete_set from "../../../assets/images/resistance_rank_complete_set.PNG";
import kaboomberg_district2 from "../../../assets/images/kaboomberg_district2.png";
import daisy_gardens_inc1 from "../../../assets/images/daisy_gardens_inc1.png";
import daisy_gardens_inc2 from "../../../assets/images/daisy_gardens_inc2.png";
import daisy_gardens_inc3 from "../../../assets/images/daisy_gardens_inc3.png";
import daisy_gardens_inc4 from "../../../assets/images/daisy_gardens_inc4.png";
import PanelNavigation from "../../../components/PanelNavigation";

class TaskForce extends React.Component {
    render() {
        const panelNavigationLinks = [
            {
                href: "#join",
                text: "How to Join the Resistance",
            },
            {
                href: "#hideout",
                text: "Task Force Hideout",
            },
            {
                href: "#toontasks",
                text: "Toontasks",
            },
            {
                href: "#kaboomberg",
                text: "Kaboomberg",
            },
        ];

        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Sellbot Task Force</h1>
                    <div className="warning-message">
                        <h4>
                            <i class="fas fa-exclamation-triangle"></i>
                            {" "}{" "}
                            <strong>Attention</strong>
                        </h4>
                        <p>
                            This page contains information and pictures in
                            relation to the Sellbot Task Force update. Before
                            continuing, please know there are spoilers ahead!
                        </p>
                    </div>
                    <p>
                        The Sellbot Task Force is a group of Toon Resistance
                        members that monitor the cogs and their tactics. Their
                        home is within the Sellbot Task Force Hideout. Once
                        Sellbot Field Offices made a landing in Toontown, the
                        Toon Resistance started seeking new toon recruits to
                        join the force.
                    </p>
                    <PanelNavigation links={panelNavigationLinks} adSlot="8689384557" />
                    <br />
                    <div style={{ marginTop: 5 + "px", textAlign: "center" }}>
                        <Link to="/fieldoffices">
                            <button className="field-office-button">
                                Looking for Sellbot Field Offices? Click here!
                            </button>
                        </Link>
                    </div>
                    <span className="anchor" id="join"></span>
                    <h2>How to Join the Resistance</h2>
                    <p>
                        Toons can join the Toon Resistance after completing all
                        the toontasks through Donald's Dreamland, earning every
                        cog disguise, and having at least one level 7 gag. Once
                        meeting these requirements, toons can start taking on
                        toontasks for the resistance. To find this, visit any
                        Toon HQ! Toons will start out by completing toontasks to
                        earn the Resistance rank. Only then will the Task Force
                        know you are serious and consider your membership.
                    </p>
                    <span className="anchor" id="hideout"></span>
                    <h2>Task Force Hideout</h2>
                    <p>
                        The Sellbot Task Force Hideout is an area built by the
                        Toon Resistance. It is located in the sewer pipes below
                        Sellbot Headquarters, accessible via the manhole cover
                        near the V.P. lobby doors. How cool!
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 270 + "px" }}
                        src={hideout_entrance}
                        alt="Sellbot Task Force Hideout entrance"
                    />
                    <p>
                        Toons can enter this hideout once they have earned their
                        Toon Resistance Trainee badge. Resistance Rangers along
                        with four Resistance Operators, reside within the Task
                        Force Hideout. They provide toontasks that toons can
                        complete when joining the Toon Resistance.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 355 + "px" }}
                        src={hideout1}
                        alt="Sellbot Task Force Hideout"
                    />
                    <span className="anchor" id="toontasks"></span>
                    <h2>Toontasks</h2>
                    <p>
                        The Task Force toontasks are split into several
                        different badge sets, meaning you cannot move on to the
                        next set of toontasks until completing the previous.
                        Once joining the Toon Resistance, a toon will be able to
                        earn badges and rank up by completing toontasks for
                        Resistance Rangers or Operators to collect stamps and
                        Sellbot skill points. There are four earnable badges in
                        the Toon Resistance:
                    </p>
                    <ol>
                        <li>Trainee</li>
                        <li>Explorer</li>
                        <li>Sleuth</li>
                        <li>Smasher</li>
                    </ol>
                    <p>
                        Each of these badges comes with their own set of
                        toontasks where toons will have to earn stamps and
                        collect Sellbot skill points. There are a total of 10
                        stamps a toon will have to collect in order to move onto
                        the next badge set. Additionally, Sellbot skill points
                        need to be collected in order to rank up. A toon will
                        need to rank up a certain amount of times to move onto
                        the next badge set.
                    </p>
                    <p>
                        A toon can keep track of their progress by visiting the
                        Resistance rank page{" "}
                        <span>
                            <img
                                className="book-tab"
                                src={resistance_rank_tab}
                                alt="Resistance rank book tab"
                            />
                        </span>{" "}
                        of the Shticker Book. Here, a toon can check how many
                        stamps they have collected so far, their current rank
                        number, how many Sellbot skill points needed to rank up,
                        and the next badge requirements. In the screenshot
                        below, 6 more stamps and 8 more rank ups, with the next
                        rank up needing 530 more Sellbot skill points, are
                        required to move onto the Sleuth badge set.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 375 + "px" }}
                        src={resistance_rank_info}
                        alt="Resistance rank book info page"
                    />
                    <p>
                        On the same note, the Resistance rank page shows if toons
                        are ready to move onto the next badge set and allows
                        toons to look back on completed sets. A completed set
                        will have all 10 stamps, a full Sellbot skill points
                        bar, and the set badge where question mark used to be.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 240 + "px" }}
                        src={resistance_rank_complete_set}
                        alt="Resistance rank completed set"
                    />
                    <p>
                        Additionally, there are toontasks to earn more laff
                        points. A total of 3 laff boosts, 1 boost per toontask,
                        are earnable in this task line. These tasks can be taken
                        on as a toon progresses through the resistance rankings.
                        There is a laff boost opportunity in Explorer set, Sleuth
                        set, and Smasher set. As a toon increases their rank, toontasks
                        that award unique toon resistance clothing and
                        accessories will become available. These items include,
                        with the required rank noted in parantheses:
                    </p>
                    <ul>
                        <li>
                            Trainee Outfit (2){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Category:Trainee_Outfit" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Pair of Trainers (3){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Pair_of_Trainers#:~:text=The%20Pair%20of%20Trainers%20are,the%20Sellbot%20Task%20Force%20ToonTasks." target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sellbot Cog-Crusher Outfit (4){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Sellbot_Cog-Crusher_Shirt" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Trainee Travel Pack (5){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Trainee_Travel_Pack" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sellbot Cog-Crusher Shoes (6){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Sellbot_Cog-Crusher_Shoes" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Vintage Teashades (7){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Vintage_Teashades" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sellbot Cog-Crusher Shades (8){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Sellbot_Cog-Crusher_Shades" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Pie Tin Topper (9){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Pie_Tin_Topper?mobile-app=true&theme=wiki" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Explorer Outfit (10){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Category:Explorer_Outfit" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Exploration Boots (12){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Exploration_Boots" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Oil Pale Pack (13){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Oil_Pale_Pack" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Experimental Eyewear (14){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Experimental_Eyewear" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Water Cooled Cap (16){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Water_Cooled_Cap" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sleuthing Sneakers (17){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Sleuthing_Sneakers" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sly Scarf (18){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Sly_Scarf" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Magnifying Glasses (19){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Magnifying_Glasses" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Sleuth Outfit (20){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Category:Sleuth_Outfit" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Ticklish Topper (22){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Ticklish_Topper" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Steel-toed Smashers (24){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Steel-toed_Smashers" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Aegis of Aluminum (26){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Aegis_of_Aluminum" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Salvaged Spectacles (28){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Salvaged_Spectacles" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Resistence Recruitment Poster (28){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Resistance_Recruitment_Poster" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Litter-less Lid (29){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Litter-less_Lid" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                        <li>
                            Smasher Outfit (30){" "}
                            <a href="https://toontownrewritten.fandom.com/wiki/Category:Smasher_Outfit" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                    </ul>
                    <p>
                        Want more details? Click{" "}
                        <a
                            href="https://docs.google.com/document/d/1L8mz5sNqz4MuDK_crW1Xh-zRfFBbDDLq3hNg5-929Qw/edit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </a>{" "}
                        to view a thorough outline of the Sellbot Task Force
                        taskline.
                    </p>
                    <span className="anchor" id="kaboomberg"></span>
                    <h2>Kaboomberg</h2>
                    <p>
                        Kaboomberg is a hidden district that went under temporary
                        lockdown after Sellbots attacked Daisy Gardens. This is where the
                        first Sellbot Field Office took over a toon headquarters on Elm Street
                        on December 1, 2021. This caused Kaboomberg to go under lockdown.
                        After the cogs successfully attacked and invaded the playground in this
                        district, they rebranded it to Daisy Gardens, Inc.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 310 + "px" }}
                        src={kaboomberg_district2}
                        alt="Kaboomberg district option"
                    />
                    <p>
                        Daisy Gardens, Inc. is completely infested with Sellbot cog buildings
                        and a four-star field office in place of toon headquarters.
                        There are no fun toon activites in this playground. The Trolley is blocked,
                        the Gag Shop is office supplies, the Pet Shop is for cosmetics, and the
                        Clothing Shop sells suits. Maple Street is Market Street, and Elm Street is
                        Wall Street. The fish pond is cog oil. And there are cogs walkings around
                        and goons within the maze.
                    </p>
                    <p>
                        Toons gain access to Kaboomberg while in the process of obtaining
                        their Smasher Badge. However, access will be revoked once the Task Force
                        storyline is completed. A work around to this is to teleport to someone
                        who is in Kaboomberg.
                    </p>
                    <div className="row row-images">
                        <div className="column">
                            <img
                                className="content-img"
                                style={{ height: 240 + "px" }}
                                src={daisy_gardens_inc1}
                                alt="Daisy Gardens, Inc."
                            />
                        </div>
                        <div className="column">
                            <img
                                className="content-img"
                                style={{ height: 240 + "px" }}
                                src={daisy_gardens_inc2}
                                alt="Daisy Gardens, Inc."
                            />
                        </div>
                    </div>
                    <div className="row row-images">
                        <div className="column">
                            <img
                                className="content-img"
                                style={{ height: 240 + "px" }}
                                src={daisy_gardens_inc3}
                                alt="Daisy Gardens, Inc."
                            />
                        </div>
                        <div className="column">
                            <img
                                className="content-img"
                                style={{ height: 240 + "px" }}
                                src={daisy_gardens_inc4}
                                alt="Daisy Gardens, Inc."
                            />
                        </div>
                    </div>
                    <h4>Earning the Smasher Badge</h4>
                    <p>
                        Earning the Smasher Badge is similar to how the first three
                        badges are earned, by completing toontasks given by Toon
                        Resistance Rangers. However, the final task is a tough challenge.
                        Toons must visit Kaboomberg to defeat a four-star Sellbot Field
                        Office.
                    </p>
                    <iframe
                        className="video-frame"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/l4RT70OJ8HY?si=1UYXRsCmK_XBv6Tm&amp;start=137"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                    <p>
                        Once toons receive their Smasher badge, it marks the end of the
                        Sellbot Task Force storyline. Congratulations!
                        After finally earning the final Task Force Badge,
                        toons can speak to Ripely and take on another
                        toontask to earn the ability to carry one additional level 7
                        gag of choice! Choose wisely, this cannot be changed later.
                    </p>
                    <br />
                    <PanelNavigation links={panelNavigationLinks} adSlot="1733984228" />
                </div>
            </div>
        );
    }
}

export default TaskForce;
