import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import ReactGA from "react-ga4";
import { createBrowserHistory } from 'history'
import "./scss/light-theme.scss";
import "./scss/dark-theme.scss";
import "./scss/animation.scss";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./views/Home";
import Contact from "./views/Contact";
import About from "./views/About";
import Faq from "./views/Faq";
import Toontips from "./views/Toontips";
import Bossbot from "./views/cogs/Bossbot";
import Cashbot from "./views/cogs/Cashbot";
import Sellbot from "./views/cogs/sellbot/Sellbot";
import Lawbot from "./views/cogs/Lawbot";
import Gardening from "./views/activities/Gardening";
import Fishing from "./views/activities/Fishing";
import Golfing from "./views/activities/Golfing";
import Racing from "./views/activities/Racing";
import Doodles from "./views/activities/Doodles";
import Activities from "./views/Activities";
import Cogs from "./views/Cogs";
import PageNotFound from "./views/PageNotFound";
import Neighborhoods from "./views/Neighborhoods";
import PrivacyPolicy from "./views/PrivacyPolicy";
import SearchResults from "./views/SearchResults";
import FieldOffices from "./views/cogs/sellbot/FieldOffices";
import TaskForce from "./views/cogs/sellbot/TaskForce";
import WhatsNew from "./views/WhatsNew";
import Gags from "./views/Gags";
import UnderNewManagement from "./views/cogs/UnderNewManagement";
import StatusEffects from "./views/StatusEffects";
import WhatsNewPopup from "./components/WhatsNewPopup";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();
    }

    componentDidMount() {
        window.process = {
            ...window.process,
        };

        // Initialize Google Analytics
        const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
        ReactGA.initialize(TRACKING_ID);

        // Track the initial page load
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search
        });

        // Set up history listener
        this.history.listen((location) => {
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname
            });
        });
    }

    render() {
        return (
            <div>
                <div id="return-top"></div>
                <Router history={this.history} basename={process.env.PUBLIC_URL}>
                    <WhatsNewPopup />
                    <ScrollToTop />
                    <Navigation />
                    <div className="container body-content">
                        <Routes>
                            <Route exact path="/" element={<Home />}></Route>
                            <Route
                                exact
                                path="/whatsnew"
                                element={<WhatsNew />}
                            ></Route>
                            <Route
                                exact
                                path="/about"
                                element={<About />}
                            ></Route>
                            <Route
                                exact
                                path="/contact"
                                element={<Contact />}
                            ></Route>
                            <Route exact path="/faq" element={<Faq />}></Route>
                            <Route
                                exact
                                path="/toontips"
                                element={<Toontips />}
                            ></Route>
                            <Route
                                exact
                                path="/bossbots"
                                element={<Bossbot />}
                            ></Route>
                            <Route
                                exact
                                path="/cashbots"
                                element={<Cashbot />}
                            ></Route>
                            <Route
                                exact
                                path="/sellbots"
                                element={<Sellbot />}
                            ></Route>
                            <Route
                                exact
                                path="/lawbots"
                                element={<Lawbot />}
                            ></Route>
                            <Route
                                exact
                                path="/gardening"
                                element={<Gardening />}
                            ></Route>
                            <Route
                                exact
                                path="/fishing"
                                element={<Fishing />}
                            ></Route>
                            <Route
                                exact
                                path="/golfing"
                                element={<Golfing />}
                            ></Route>
                            <Route
                                exact
                                path="/racing"
                                element={<Racing />}
                            ></Route>
                            <Route
                                exact
                                path="/doodles"
                                element={<Doodles />}
                            ></Route>
                            <Route
                                exact
                                path="/activities"
                                element={<Activities />}
                            ></Route>
                            <Route exact path="/cogs" element={<Cogs />}></Route>
                            <Route
                                exact
                                path="/neighborhoods"
                                element={<Neighborhoods />}
                            ></Route>
                            <Route
                                exact
                                path="/privacypolicy"
                                element={<PrivacyPolicy />}
                            ></Route>
                            <Route
                                exact
                                path="/search/:searchQuery?"
                                element={<SearchResults />}
                            ></Route>
                            <Route
                                exact
                                path="/fieldoffices"
                                element={<FieldOffices />}
                            ></Route>
                            <Route
                                exact
                                path="/taskforce"
                                element={<TaskForce />}
                            ></Route>
                            <Route
                                exact
                                path="/gags"
                                element={<Gags />}
                            ></Route>
                            <Route
                                exact
                                path="/undernewmanagement"
                                element={<UnderNewManagement />}
                            ></Route>
                            <Route
                                exact
                                path="/statuseffects"
                                element={<StatusEffects />}
                            ></Route>
                            <Route
                                exact
                                path="*"
                                element={<PageNotFound />}
                            ></Route>
                        </Routes>
                        <Footer />
                    </div>
                    <div className="disclaimer">
                        Images used and graphics made on this site may use content
                        from Toontown Rewritten or Disney's Toontown Online. I do
                        not claim ownership of this content.
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
